<script setup lang="ts">
import {PropType} from "vue";
import {FormKitFrameworkContext, FormKitSchemaDefinition} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateItem from "~/components/AppDropdownCreateItem.vue";
import {AppPermissions} from "~/types/Permissions";
import {useFetch} from "#app";
import {useDialog} from "primevue/usedialog";
import {useToast} from "primevue/usetoast";
import currencies, {Currency} from "currencies.json";
import getCountryByCurrency from 'currency-to-country';


const dialog = useDialog()
const toast = useToast()

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext>
})

const returnCode  = props.context?.attrs?.returnCode || props.context?.returnCode || undefined
const onlyEnabled = props.context?.attrs?.onlyEnabled || props.context?.onlyEnabled || true
const allowCreate = props.context?.attrs?.allowCreate || props.context?.allowCreate || false
const selectType = props.context?.attrs?.selectType || 'single' as 'single' | 'multi'

const apiEndpoint = computed(() => {
  const base = `/api/v1/currencies`

  if (onlyEnabled) {
    return `${base}?enabled=true`
  }
})

const currencyCodeToNameMap = currencies.currencies.reduce((acc: any, currency: Currency) => {
  acc[currency.code] = currency.name
  return acc
}, {})

const currencyOptions = currencies.currencies.map((currency: Currency) => {
  return {
    label: currency.code + ' - ' + currency.name,
    value: currency.code,
  }
})

const onSelect = (props: any) => {
  console.log('onSelect', props)
}

const headerComponentProps = {
  title: 'Währung hinzufügen',
  fieldSchema: {
    $formkit: 'primeDropdown',
    filter: true,
    validation: 'required',
    validationLabel: 'Währung',
    placeholder: 'Währung',
    options: currencyOptions,
    classes: {
      outer: 'flex-1',
    },
    name: 'code',
    onInput: onSelect,
  } as FormKitSchemaDefinition,
  asModal: true,
  modalSubmitLabel: 'Währung hinzufügen',
  onSubmit: async (data: any, { toast , refresh, setInputValue }) => {
    await useFetch('/api/v1/currencies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        code: data.code,
        enabled: true,
      },
      async onResponse({ response }) {
        if (response.status === 201) {

          await refresh()

          toast.add({
            severity: 'success',
            summary: 'Währung hinzugefügt',
            detail: `Die Währung "${response._data.code}" wurde erfolgreich hinzugefügt.`,
            life: 3500,
          })

          const value = returnCode ? response._data.code : response._data.id;

          if (selectType === 'multi') {
            setInputValue([value])
          } else {
            setInputValue(value)
          }
        } else {
          toast.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Die Währung konnte nicht hinzugefügt werden.`,
            life: 3500
          })
        }
      }
    })

    // wait 1 sec
    await new Promise(resolve => setTimeout(resolve, 1000))
  }
}

const apiTransformFunction = (data: any, { lang }) => {
  const countries = getCountryByCurrency(data.code, { priorityCountries: ['DE']});
  const countryCode = countries.length ? countries[0].countryCode : null;

  return {
    ...data,
    label: data.code + ' - ' + currencyCodeToNameMap[data.code],
    value: returnCode ? data.code : data.id,
    flagPath: countryCode ? '/images/flags-svg/1x1/' + countryCode?.toLowerCase() + '.svg' : null,
  };
}
</script>

<template>
  <div>
    <ApiDropdown
      :type="selectType"
      :context="context"
      :api-endpoint="apiEndpoint"
      :api-transform-function="apiTransformFunction"
      :default-placeholder="'Währung auswählen'"
      :preload-options="true"
      :default-value="true"
      :header-component="allowCreate ? AppDropdownCreateItem : undefined"
      :header-component-props="allowCreate ? headerComponentProps : undefined"
      :header-component-permissions="[AppPermissions.CurrencyWrite]"
    >
      <template #option="{ option }">
        <div class="flex items-center gap-2">
          <img v-if="option.flagPath" :src="option.flagPath" class="w-[1.25rem] h-[1.25rem] rounded-full overflow-hidden object-cover border" />
          <span>{{ option.label }}</span>
        </div>
      </template>
    </ApiDropdown>
  </div>
</template>
