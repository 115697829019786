import {PricePlan} from "~/types/billing";
import {Ref} from "@vue/reactivity";
import {ComputedRef} from "vue";

export const usePricePlanDifference = (currentPricePlan: Ref<PricePlan>, originalPricePlan?: Ref<PricePlan>): {
  pricePlanDifference: ComputedRef<{
    readableAmount: string;
    percentage: number;
    value: number;
  } | null>;
  isPositiveOrNegative: ComputedRef<'negative'|'positive'|'equal'>;
} => {
  const pricePlanDifference = computed(() => {
    if (!currentPricePlan.value || !originalPricePlan?.value) {
      return null;
    }

    switch (currentPricePlan.value?.type) {
      case 'flat_fee':
      case 'per_unit': {
        const diff = currentPricePlan.value?.price?.amount - originalPricePlan.value?.price?.amount;

        const value = formatMoney({
          amount: diff,
          currencyCode: currentPricePlan.value?.currencyCode,
          precision: 0,
        });

        const percentage = (diff / originalPricePlan.value?.price?.amount) * 100;

        return {
          readableAmount: value,
          percentage,
          value: diff,
        };
      }
      case 'volume':
      case 'tiered':
      case 'stair_step': {
        return {
          value: 0,
          percentage: 0,
          readableAmount: 'N/A',
        };
      }
      case 'percentage': {
        const diff = currentPricePlan.value?.percentage - originalPricePlan.value?.percentage;

        return {
          readableAmount: `${diff} %`,
          percentage: diff,
          value: diff,
        };
      }
      default:
        return null;
    }
  });

  const isPositiveOrNegative = computed<'negative'|'positive'|'equal'>(() => {
    if (!pricePlanDifference.value?.value || pricePlanDifference.value.value == 0) {
      return 'equal';
    }

    return pricePlanDifference.value.value > 0 ? 'positive' : 'negative';
  });

  return {
    pricePlanDifference,
    isPositiveOrNegative,
  };
};
