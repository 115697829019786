<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import {useDialog} from "primevue/usedialog";
import AppDropdownCreateItem from "~/components/AppDropdownCreateItem.vue";
import {useToast} from "primevue/usetoast";
import {useFetch} from "#app";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import {AppPermissions} from "~/types/Permissions";

const dialog = useDialog()
const toast = useToast()

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const apiTransformFunction = (data: any) => {
  return {
    ...data,
    label: data.name,
    value: data.id,
  }
}

const headerComponentProps = {
  title: 'Neue Plan Familie hinzufügen',
  onSubmit: async (data: any, { toast , refresh, setInputValue }) => {
    await useFetch('/api/v1/plan-families', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      async onResponse({ response }) {
        if (response.status === 201) {
          toast.add({
            severity: 'success',
            summary: 'Plan Familie erstellt',
            detail: `Die Plan Familie "${response._data.name}" wurde erfolgreich erstellt.`,
            life: 3500,
          })

          await refresh()

          setInputValue([response._data.id])
        } else {
          toast.add({
            severity: 'error',
            summary: 'Fehler',
            detail: `Die Plan Familie konnte nicht erstellt werden.`,
            life: 3500
          })
        }
      }
    })

    // wait 1 sec
    await new Promise(resolve => setTimeout(resolve, 1000))
  }
}
</script>

<template>
  <div>
    <ApiDropdown
      type="single"
      :context="context"
      :api-endpoint="'/api/v1/plan-families'"
      :api-transform-function="apiTransformFunction"
      :default-placeholder="'Plan Familie auswählen'"
      :header-component="AppDropdownCreateItem"
      :header-component-props="headerComponentProps"
      :header-component-permissions="[AppPermissions.PlanWrite]"
    />
  </div>
</template>
