<script setup lang="ts">
import {nextTick, PropType} from "vue";
import {FormKitContext, FormKitFrameworkContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import {useDialog} from "primevue/usedialog";
import CreateCustomerForm from "~/components/Customer/CreateCustomerForm.vue";
import {AppPermissions} from "~/types/Permissions";

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext>
})

const dialog = useDialog()
const emit = defineEmits(['onRawInput'])

const customerId = computed(() => props.context?.customerId || null)
props.context.attrs.filter = true

const apiEndpoint = computed(() => {
  return '/api/v1/partners'
})

const headerComponentProps = {
  title: 'Partner erstellen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(CreateCustomerForm, {
      props: {
        header: 'Partner erstellen',
        modal: true,
      },
      data: {
        type: 'partner'
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await refresh()
          setInputValue(data.data.id)
        }
      }
    })
  }
};

const selectedCustomer = ref<null|any>(null)
const onRawInput = (value: any) => {
  selectedCustomer.value = value

  emit('onRawInput', value)
}

onMounted(() => {
  props.context?.node?.on('input', async (value: any) => {
    console.log('on input')
    const defaultCurrencyField = props.context?.attrs?.currencyField || props.context?.currencyField || null
    const defaultInvoiceAddressField = props.context?.attrs?.invoiceAddressField || props.context?.invoiceAddressField || null
    const defaultPaymentMethodField = props.context?.attrs?.paymentMethodField || props.context?.paymentMethodField || null

    // wait 100ms, so the invoiceAddress and paymentMethodNode can appear in DOM, and we can find them in formkit node
    await nextTick(async () => await new Promise(resolve => setTimeout(resolve, 100)))

    const node = props.context?.node as FormKitContext
    const defaultCurrencyNode = node?.parent?.find(defaultCurrencyField)
    const defaultInvoiceAddressNode = node?.parent?.find(defaultInvoiceAddressField)
    const defaultPaymentMethodNode = node?.parent?.find(defaultPaymentMethodField)

    // if the input is not from the dropdown selection, skip
    if (value.payload !== selectedCustomer.value?.id) {
      defaultCurrencyNode?.input(null)
      defaultInvoiceAddressNode?.input(null)
      defaultPaymentMethodNode?.input(null)

      return
    }

    if (defaultCurrencyNode) {
      defaultCurrencyNode.input(selectedCustomer.value?.currencyCode)
    }

    if (defaultInvoiceAddressNode) {
      defaultInvoiceAddressNode.input(selectedCustomer.value?.defaultInvoiceAddress ?? selectedCustomer.value?.defaultAddress ?? null)
    }

    if (defaultPaymentMethodNode) {
      defaultPaymentMethodNode.input(selectedCustomer.value?.defaultPaymentMethod ?? null)
    }
  })
})

</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="apiEndpoint"
      :show-filter="true"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.companyName ? data.companyName : `${data.firstName} ${data.lastName}`,
          value: data.id
        }
      }"
      :default-placeholder="'Partner auswählen'"
      :preload-options="true"
      :header-component="AppDropdownCreateButton"
      :header-component-props="headerComponentProps"
      :header-component-permissions="[AppPermissions.PartnerWrite]"
      @on-raw-input="onRawInput"
    />
  </div>
</template>
