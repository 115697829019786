import {createMessage, FormKitNode} from "@formkit/core";

const requiredNullable = function (node: FormKitNode) {
  node.store.remove('required_nullable_invalid');

  if (node.value === null) {
    return true
  }

  if (!node.value) {
    const invalidMessage = createMessage({
      key: 'required_nullable_invalid',
      value: 'Dieses Feld ist erforderlich.',
      visible: true,
      blocking: true,
      type: 'error',
    });

    node.store.set(invalidMessage);

    return false;
  }

  return true;
}

export default requiredNullable;
