<script setup lang="ts">
import {PropType} from "vue";
import type {FormKitContext} from "@formkit/core";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import {AppPermissions} from "~/types/Permissions";
import {useApiDropdown} from "~/store/api-dropdown";
import MeasurementForm from "~/components/Measurement/MeasurementForm.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const modal = useModal();

const headerComponentProps = {
  title: 'Neue abrechenbare Einheit hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    modal.open(MeasurementForm, {
      props: {
        header: 'Neue abrechenbare Einheit hinzufügen',
        modal: true,
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.attrs.onCreate?.(data.data)
          await refresh()
          useApiDropdown().addItem(data.data)

          setInputValue(data.data.id)
        }
      }
    })
  }
}
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :header-component="AppDropdownCreateButton"
      :header-component-props="headerComponentProps"
      :api-endpoint="'/api/v1/catalogue/measurements'"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.unit.name + ' (' + lang('enums.MeasurementType.' + data.type + '.label') + ')',
          value: data.id,
        }
      }"
      :default-placeholder="'Einheit auswählen'"
      :header-component-permissions="[AppPermissions.MeasurementWrite]"
    />
  </div>
</template>
