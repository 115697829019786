import { DirectiveBinding } from 'vue';
import { usePermissions } from '~/composables/usePermissions';

const checkPermission = (el: any, permission: string, arg: string | undefined, t: any, canAny: any) => {
  if (!canAny([permission])) {
    if (arg === 'show-note') {
      const note = document.createElement('div');

      note.classList.add('permission-note');
      note.innerHTML = t('permission.note', { permission });
      note.classList.add(...'permission-note text-sm text-gray-900 bg-white p-2 rounded-md'.split(' '));

      el.parentNode?.insertBefore(note, el);

      // when page reloads remove the note
      window.addEventListener('beforeunload', () => {
        note.parentNode?.removeChild(note);
      });
    }

    if (!arg) {
      if (el.parentNode) {
        el.parentNode?.removeChild(el);
      } else {
        el.style.display = 'none';
      }
    }

    if (arg === 'disable') {
      el.setAttribute('disabled', 'disabled');
      el.classList.add('disabled');
    }

    if (arg === 'readonly') {
      el.setAttribute('readonly', 'readonly');
    }

    if (arg === 'show') {
      el.style.display = 'block';
    }

    if (arg === 'class') {
      el.classList.add('disabled');
    }

    if (el) {
      el.setAttribute('title', 'Unzureichende Berechtigungen');
      el.setAttribute('permissions-title', 'Unzureichende Berechtigungen');
    }
  }
};

const handlePermissions = (el: any, binding: DirectiveBinding, t: any, canAny: any) => {
  const { value, arg } = binding;

  if (Array.isArray(value)) {
    value.forEach((permission: string) => {
      checkPermission(el, permission, arg, t, canAny);
    });
  }

  if (typeof value === 'string') {
    checkPermission(el, value, arg, t, canAny);
  }
};

export default defineNuxtPlugin((nuxtApp) => {
  const { canAny } = usePermissions();

  nuxtApp.vueApp.directive('canAny', {
    mounted(el, binding) {
      if (!canAny(binding.value)) {
        el.parentNode?.removeChild(el);
      }
    },
    updated(el, binding) {
      if (!canAny(binding.value)) {
        el.parentNode?.removeChild(el);
      }
    },
  });

  nuxtApp.vueApp.directive('can', {
    mounted(el, binding) {
      handlePermissions(
        el,
        binding,
        () => {
          return 'Du hast unzureichende Berechtigungen, um dieses Element zu sehen.';
        },
        canAny
      );
    },
    updated(el, binding) {
      handlePermissions(
        el,
        binding,
        () => {
          return 'Du hast unzureichende Berechtigungen, um dieses Element zu sehen.';
        },
        canAny
      );
    },
  });
});
