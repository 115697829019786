import {ReturnType} from "birpc";
import {useConfirm} from "primevue/useconfirm";
import useModal from "~/composables/useModal";
import useMessage from "~/composables/useMessage";
import {$fetch} from "ofetch";

export type CostCentre = {
  id?: string;
  name?: string;
  code?: string;
  status?: 'active' | 'archived';
  type?: 'KOST1' | 'KOST2';
}

export class CostCentreService {
  private confirm: ReturnType<typeof useConfirm>
  private modal: ReturnType<typeof useModal>
  private message: ReturnType<typeof useMessage>

  public constructor(
    confirm: ReturnType<typeof useConfirm>,
    modal: ReturnType<typeof useModal>,
    message: ReturnType<typeof useMessage>
  ) {
    this.confirm = confirm
    this.modal = modal
    this.message = message
  }

  public async createCostCentre(form: CostCentre): Promise<CostCentre|null> {
    try {
      const response = await $fetch('/api/v1/accounting/cost-centres', {
        method: 'POST',
        body: form,
      })

      this.message.success('Kostenstelle wurde erstellt.')

      return response;
    } catch (e) {
      this.message.error('Fehler beim Erstellen der Kostenstelle')

      return null;
    }
  }

  public async updateCostCentre(form: CostCentre): Promise<CostCentre|null> {
    try {
      const response = await $fetch(`/api/v1/accounting/cost-centres/${form.id}`, {
        method: 'PATCH',
        body: form,
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      })

      this.message.success('Kostenstelle wurde aktualisiert.')

      return response;
    } catch (e) {
      this.message.error('Fehler beim Aktualisieren des Kostenstelle')

      return null;
    }
  }

  public async archiveCostCentre(id: string): Promise<CostCentre|null> {
    try {
      const response = await $fetch(`/api/v1/accounting/cost-centres/${id}`, {
        method: 'PATCH',
        body: {
          status: 'archived'
        }
      })

      this.message.success('Kostenstelle wurde archiviert.')

      return response;
    } catch (e) {
      this.message.error('Fehler beim Archivieren des Kostenstelle')

      return null;
    }
  }

  public async unarchiveCostCentre(id: string): Promise<CostCentre|null> {
    try {
      const response = await $fetch(`/api/v1/accounting/cost-centres/${id}`, {
        method: 'PATCH',
        body: {
          status: 'active'
        }
      })

      this.message.success('Kostenstelle wurde wiederhergestellt.')

      return response;
    } catch (e) {
      this.message.error('Fehler beim Wiederherstellen des Kostenstelle')

      return null;
    }
  }

  public importCostCentres(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return $fetch('/api/v1/accounting/cost-centres/import', {
      method: 'POST',
      body: formData,
      onResponse: ({ response }) => {
        if (response.ok) {
          this.message.success('Kostenstellen wurden importiert.')
        } else {
          this.message.error('Fehler beim Importieren der Kostenstellen.')
        }
      }
    })
  }
}
