<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
import CheckoutLinkFormFields from "~/components/CheckoutLink/CheckoutLinkFormFields.vue";
import {CheckoutLinkService} from "~/services/CheckoutLinkService";
import {useConfirm} from "primevue/useconfirm";
import {AppPermissions} from "~/types/Permissions";

const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef');
const { error } = useMessage();
const checkoutLinkService = new CheckoutLinkService(useConfirm(), useModal(), useMessage());

const linkId: string = dialogRef?.value.data?.linkId;
const checkoutLink = ref();

await $fetch('/api/v1/checkout-links/' + linkId).then((value: any) => {
  checkoutLink.value = {
    ...value,
    afterCheckout: {
      ...value.afterCheckout,
      actionType: value.afterCheckout.action === 'confirmation_message' && value.afterCheckout.confirmationMessage !== null ? 'custom_confirmation_message' : value.afterCheckout.action,
    },
    periods: value.items[0].periods,
  }

  console.log(checkoutLink.value);
}).catch((response) => {
  dialogRef?.value.close();

  error('Der Checkout-Link konnte nicht gefunden werden.');
});

const onSubmit = async (form: any) => {
  const formData = {
    ...form,
    afterCheckout: {
      ...form.afterCheckout,
      action: form.afterCheckout.actionType === 'custom_confirmation_message' ? 'confirmation_message' : form.afterCheckout.actionType,
    },
    items: form.items.map((item: any) => {
      return {
        ...item,
        plan: item?.plan?.id ?? item?.plan,
        price: item?.price?.id ?? item?.price,
        product: item?.product?.id ?? item?.product,
        periods: form.periods,
      };
    }),
  };

  delete formData.afterCheckout.actionType;
  delete formData.periods;

  const apiResponse = await checkoutLinkService.updateCheckoutLink(linkId, formData, dialogRef?.value.data.onSuccess, dialogRef?.value.data.onError);
  checkoutLink.value = {
    ...apiResponse,
    periods: apiResponse.items[0].periods,
  };
};
</script>

<template>
  <div>
    <FormKit type="form" :actions="false" @submit="onSubmit" v-model="checkoutLink" :disabled="!usePermissions().can(AppPermissions.CheckoutLinkWrite)" #default="{ value }">
      <FormKit type="copyText" :value="checkoutLink.url" label="URL" :ignore="true" />
      <CheckoutLinkFormFields :form-value="value" />

      <FormKit type="primeInputSwitch" name="isEnabled" label="Aktiviert" :model-value="true" help="Wenn deaktiviert, kann der Link nicht mehr verwendet werden." />

      <FormKit v-if="usePermissions().can(AppPermissions.CheckoutLinkWrite)" type="submitButton" create-label="Checkout-Link speichern" update-label="Checkout-Link aktualisieren" :entity-id="true" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
