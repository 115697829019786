import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/usr/src/app/middleware/auth.global.ts";
import feature_45flags_45global from "/usr/src/app/middleware/featureFlags.global.ts";
import permissions_45global from "/usr/src/app/middleware/permissions.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  feature_45flags_45global,
  permissions_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}