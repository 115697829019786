<script lang="ts" setup>
import { ColorPickerRoot } from '@ark-ui/vue'
import PickColors from 'vue-pick-colors'
import {FormKitFrameworkContext} from "@formkit/core";
import {PropType} from "vue";

const props = defineProps({
  context: {
    type: Object as PropType<FormKitFrameworkContext>,
    required: true,
  },
})

const onUpdate = (value: string) => {
  props.context.node.input(value);
}
</script>

<template>
  <div>
    <PickColors :value="context._value" @update:value="onUpdate" :colors="[
        '#0B0C0E',
        '#737479',
        '#f1f1e7',
        '#DEEE3C',
    ]" />
  </div>
</template>
