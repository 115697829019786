import { useDialog } from 'primevue/usedialog';
import type { DynamicDialogInstance, DynamicDialogOptions } from 'primevue/dynamicdialogoptions';
import AppLoadingDialogContent from '~/components/AppLoadingDialogContent.vue';
import type {Ref} from "@vue/reactivity";
import AppModalSuspense from "~/components/AppModalSuspense.vue";

export default function useModal() {
  const dialog = useDialog();

  return {
    open(component: any, options: DynamicDialogOptions): DynamicDialogInstance {
      const dialogInstance = dialog.open(component, {
        ...options,
        props: {
          closable: true,
          closeOnEscape: options.props?.closable !== false,
          draggable: false,
          modal: true,
          ...options.props,
        },
      });

      return dialogInstance;
    },
    openSuspense(component: any, options: DynamicDialogOptions): Promise<DynamicDialogInstance> {
      const dialogInstance = dialog.open(AppModalSuspense, {
        ...options,
        props: {
          closable: true,
          closeOnEscape: options.props?.closable !== false,
          draggable: false,
          modal: true,
          ...options.props,
        },
        data: {
          ...options?.data || {},
          component: markRaw(component),
          props: options?.data || {},
        }
      });

      return dialogInstance;
    },
    disableClose(instance: DynamicDialogInstance): void {
      if (!instance) {
        return;
      }

      if (!instance.options) {
        instance.options = {};
      }

      if (!instance.options.props) {
        instance.options.props = {};
      }

      instance.options.props.closable = false;
      instance.options.props.closeOnEscape = false;
    },
    enableClose(instance: DynamicDialogInstance): void {
      if (!instance) {
        return;
      }

      if (!instance.options) {
        instance.options = {};
      }

      if (!instance.options.props) {
        instance.options.props = {};
      }

      instance.options.props.closable = true;
      instance.options.props.closeOnEscape = true;
    },
    loading(text: string|Ref<string>): DynamicDialogInstance {
      return dialog.open(AppLoadingDialogContent, {
        props: {
          closable: false,
          closeOnEscape: false,
          draggable: false,
          modal: true,
          showHeader: false,
        },
        data: {
          message: text,
        },
      });
    },
    loadingProgress(text: string|Ref<string>, total: number): {instance: DynamicDialogInstance, advance: (value?: number) => void} {
      const progress: Ref<number> = ref(0);
      const advance = (value?: number): void => {
        if (value) {
          progress.value = value;
        } else {
          progress.value += 1;
        }
      };

      const instance =  dialog.open(AppLoadingDialogContent, {
        props: {
          closable: false,
          closeOnEscape: false,
          draggable: false,
          modal: true,
          showHeader: false,
        },
        data: {
          message: text,
          progress,
          total,
        },
      });

      return {instance, advance};
    },
    maximize(instance: DynamicDialogInstance): void {
      if (!instance) {
        return;
      }

      if (!document) {
        return;
      }

      if (!document.body) {
        return;
      }

      document.querySelector('.p-dialog')?.classList.add('p-dialog-maximized');
    },
    minimize(instance: DynamicDialogInstance): void {
      if (!instance) {
        return;
      }

      if (!document) {
        return;
      }

      if (!document.body) {
        return;
      }

      document.querySelector('.p-dialog')?.classList.remove('p-dialog-maximized');
    },
  };
}
