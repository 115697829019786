<script setup lang="ts">
import {PropType, onMounted, ComputedRef, reactive} from "vue";
import {FormKitFrameworkContext, FormKitSchemaDefinition} from "@formkit/core";
import {useDialog} from "primevue/usedialog";
import {useToast} from "primevue/usetoast";
import {useFetch} from "#app";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import {usePermissions} from "~/composables/usePermissions";
import {useApiDropdown} from "~/store/api-dropdown";
import {useFatalError} from "~/composables/useFatalError";
import {useI18n} from "vue-i18n";

const dialog = useDialog();
const toast = useToast();
const i18n = useI18n();

type ApiDropdownProps = {
  defaultPlaceholder?: string;
  preselectOption?: boolean;
  defaultField?: string;
  defaultValue?: string;
  defaultOption?: string;
};

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext & ApiDropdownProps>,
  componentType: {
    type: String as PropType<'dropdown' | 'tree'>,
    required: false,
    default: 'dropdown',
  },
  showFilter: {
    type: Boolean,
    required: false,
    default: false,
  },
  apiEndpoint: {
    type: String,
    required: false,
  },
  apiTransformFunction: {
    type: Function as PropType<(data: any, { lang }: { lang: (t: string) => string }) => any>,
    required: false,
  },
  defaultPlaceholder: {
    type: String,
    required: false,
  },
  headerComponent: {
    type: Object,
    required: false,
  },
  headerComponentProps: {
    type: Object as PropType<{
      title: string,
      fieldSchema?: FormKitSchemaDefinition,
      onSubmit?: (data: any, { refresh, setInputValue }: { refresh: () => Promise<void>, setInputValue: (value: any) => void }) => Promise<void>
      asModal?: boolean,
      modalSubmitLabel?: string,
    }>,
    required: false,
  },
  type: {
    type: String as PropType<'single' | 'multi'>,
    required: false,
    default: 'multi',
  },
  preloadOptions: {
    type: Boolean,
    required: false,
    default: false,
  },
  defaultOption: {
    type: Object,
    required: false,
    default: undefined,
  },
  headerComponentPermissions: {
    type: Array as PropType<string[]>,
    required: false,
    default: undefined,
  },
  defaultValue: {
    type: [String, Array, Boolean],
    required: false,
    default: undefined,
  },
  defaultField: {
    type: String,
    required: false,
    default: undefined,
  },
  preselectOption: {
    type: Boolean,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(['onRawInput']);

const context: FormKitFrameworkContext = props.context;
const attrs: Record<string, any> = context?.attrs;

const apiEndpoint: ComputedRef<string> = computed(() => attrs.apiEndpoint ?? props.apiEndpoint ?? false)
const apiTransformFunction: (response: any) => any = attrs.apiTransformFunction ?? props.apiTransformFunction ?? false
const defaultPlaceholder: string = props.context?.defaultPlaceholder ?? attrs.defaultPlaceholder ?? props.defaultPlaceholder ?? 'Bitte auswählen'
const headerComponent: any = attrs.headerComponent ?? props.headerComponent ?? false
const headerComponentProps: any = attrs.headerComponentProps ?? props.headerComponentProps ?? {}
const preselectOption: boolean = props.context?.preselectOption ?? attrs.preselectOption ?? props.preselectOption ?? false
const defaultField: string = props.context?.defaultField ?? attrs.defaultField ?? props?.defaultField ?? 'default'
const defaultOptionFieldValue: any = props.context?.defaultValue ?? attrs.defaultValue ?? props.defaultValue ?? undefined
const defaultOption: any = props.context?.defaultOption ?? attrs.defaultOption ?? props.defaultOption ?? undefined
const preloadOptions: boolean = preselectOption && !defaultOption ? true : (attrs.preloadOptions ?? props.preloadOptions ?? false);

const showHeader = computed(() => {
  if (!headerComponent) {
    return false;
  }

  if (props.headerComponentPermissions) {
    return usePermissions().canAny(props.headerComponentPermissions);
  }

  return true;
});

if (!apiEndpoint.value) {
  throw new Error('apiEndpoint is required');
}

if (!apiTransformFunction) {
  throw new Error('apiTransformFunction is required');
}

if (context._value && typeof context._value === 'object') {
  emit('onRawInput', context._value);
}

function handleBlur(e: any) {
  context?.handlers.blur(e.value);
}
function handleInput(e: any) {
  const rawValue = attrs?.options.find((option: any) => option.value === e.value);
  emit('onRawInput', rawValue);
  context?.node.input(e.value);
}

const addContextInputValues = () => {
  if (attrs.optionsAdded) {
    return
  }
  // add context input values

  const isCurrentInputValueObjectOrArray = typeof context?.node._value === 'object';
  if (isCurrentInputValueObjectOrArray) {
    const defaultValue = props.type === 'multi' ? [] : '';
    const currentOptions = attrs.options ?? [];
    const currentValueOptionsOrOption = context?.node._value ?? defaultValue;

    if (props.type === 'multi') {
      // selected options, e.g. [{id: 1, name: 'foo'}, {id: 2, name: 'bar'}] should be added to the options
      // so that the user can see the selected options in the dropdown before the api call is executed
      const currentValueOptions = currentValueOptionsOrOption ?? [];

      // check if currentValueOptions are only a string or an object
      const isCurrentValueOptionsOnlyStrings = currentValueOptions.every((value: any) => {
        return typeof value === 'string';
      })

      if (isCurrentValueOptionsOnlyStrings) {
        // if currentValueOptions are only strings, we don't need to add them to the options, they should already be there
        return;
      }

      attrs.options = [...currentOptions, ...currentValueOptions.map((value: any) => {
        return apiTransformFunction(value, {
          lang: i18n.t
        });
      })]

      // add defaultOption on first position
      if (defaultOption) {
        attrs.options.unshift(defaultOption)
      }

      const currentInputValue = currentValueOptions.map((value: any) => value.id);
      if (currentInputValue.length > 0) {
        context?.node.input(currentInputValue);
      } else if (preselectOption) {
        preselectDefaultOptionIfExists();
      }
      attrs.optionsAdded = true;
    } else {
      const currentValueOption = currentValueOptionsOrOption ?? '';

      if (typeof currentValueOption === 'string') {
        // if currentValueOption is only a string, we don't need to add it to the options, it should already be there
        return;
      }

      attrs.options = [...currentOptions, apiTransformFunction(currentValueOption, {
        lang: i18n.t
      })]

      // add defaultOption on first position
      if (defaultOption) {
        attrs.options.unshift(defaultOption);
      }

      if (currentValueOption?.id) {
        context?.node.input(currentValueOption.id);
      } else if (preselectOption) {
        preselectDefaultOptionIfExists();
      }

      attrs.optionsAdded = true;
    }
  }
};

const convertValueToIdOrIds = () => {
  if (props.type === 'multi') {
    const currentValue = context?.node._value ?? [];
    if (!Array.isArray(currentValue)) {
      throw new Error('[ApiDropdown] currentValue must be an array when type is multi (1)');
    }

    // check if currentValue is only a string or an object
    const isCurrentValueOnlyStrings = currentValue.every((value: any) => {
      return typeof value === 'string';
    });
    if (isCurrentValueOnlyStrings) {
      // if currentValue is only strings, we don't need to convert it to ids
      return;
    }

    const currentValueIds = currentValue.map((value: any) => value.id)
    context?.node.input(currentValueIds);
  } else {
    const currentValue = context?.node._value ?? '';

    // check if currentValue is a string or an object
    const isCurrentValueOnlyString = typeof currentValue === 'string';
    if (isCurrentValueOnlyString) {
      // if currentValue is only a string, we don't need to convert it to an id
      return;
    }

    // check if current value is a real object (not null, etc)
    if (!currentValue) {
      return;
    }

    const currentValueId = currentValue.id;
    context?.node.input(currentValueId);
  }
}

watch(props.context, (value, oldValue, onCleanup) => {
  addContextInputValues();
  convertValueToIdOrIds();
});

const showCreate = ref(false);

const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid) ? `${attrs?.class ?? ''} p-invalid` : attrs?.class ?? '')

const currentPageMeta = reactive({
  currentPage: 1,
  lastPage: undefined,
  itemsPerPage: 20,
  totalItems: undefined,
  pageTotalItems: undefined,
});

// when props changed (e.g. apiEndpoint), reset currentPage
watch(() => props, () => {
  currentPageMeta.currentPage = 1
  currentPageMeta.totalItems = undefined
  currentPageMeta.pageTotalItems = undefined
  currentPageMeta.itemsPerPage = 50
  currentPageMeta.lastPage = undefined
});

const apiEndpointWithQuery = computed(() => {
  const query = {
    page: currentPageMeta.currentPage,
    limit: currentPageMeta.itemsPerPage,
  };

  const queryParams = new URLSearchParams(query).toString();

  if (apiEndpoint.value.includes('?')) {
    return `${apiEndpoint.value}&${queryParams}`;
  }

  return `${apiEndpoint.value}?${queryParams}`;
});

const preselectDefaultOptionIfExists = () => {
  if (attrs.options.length > 0 && !context?.node?._value) {
    // find by defaultField
    const foundDefaultOption = attrs.options.find((option: any) => {
      return option[defaultField] === defaultOptionFieldValue
    }) ?? attrs.options[0] ?? defaultOption?.value;

    context?.node.input(foundDefaultOption[attrs.optionValue ?? 'value'])
  }
}

const isExecutedInit = ref(false)

const { pending, execute, status, refresh } = useFetch(apiEndpointWithQuery, {
  immediate: preloadOptions,
  server: false,
  onRequest() {
    isExecutedInit.value = true
  },
  onResponse({ response }): Promise<void> | void {
    if (response.status !== 200) {
      useFatalError().show();
      return
    }

    if (response._data?.meta && response._data.meta?.pagination) {
      currentPageMeta.totalItems = response._data.meta.pagination.totalItems
      currentPageMeta.pageTotalItems = response._data.meta.pagination.pageTotalItems
      currentPageMeta.itemsPerPage = response._data.meta.pagination.itemsPerPage
      currentPageMeta.currentPage = response._data.meta.pagination.currentPage
      currentPageMeta.lastPage = response._data.meta.pagination.lastPage
    }

    const existingItems: {
      id: string
      name: string
    }[] = attrs.options ?? []

    const dataItems = response._data.data.map((dataItem: any) => {
      return apiTransformFunction(dataItem, {
        lang: i18n.t
      })
    })

    dataItems.push(...existingItems.map((dataItem: any) => {
      return apiTransformFunction(dataItem, {
        lang: i18n.t
      })
    }))

    // add defaultOption on first position
    if (defaultOption) {
      dataItems.unshift(defaultOption)
    }

    // clear duplicates
    const uniqueItems = dataItems.filter((dataItem, index, self) =>
      index === self.findIndex((t) => (
        t.value === dataItem.value
      ))
    )

    attrs.options = uniqueItems

    uniqueItems.forEach(item => useApiDropdown().addItem(item))

    if (isExecutedInit && preselectOption) {
      preselectDefaultOptionIfExists();
    }
  }
})

const onBeforeShow = async () => {
  if (status.value === 'pending') {
    await execute()
  } else {
    await refresh()
  }

  isExecutedInit.value = true
}

const placeholder = computed(() => {
  return attrs?.placeholder ?? defaultPlaceholder
})

const isLoading = computed(() => {
  if (!isExecutedInit.value) {
    return false
  }
  return pending.value
})

const setInput = (value: any) => {
  if (props.type === 'multi' && !Array.isArray(value)) {
    throw new Error('[ApiDropdown] setInputValue must be called with an array when type is multi')
  } else if (props.type === 'single' && Array.isArray(value)) {
    throw new Error('[ApiDropdown] setInputValue must be called with a string when type is single')
  }

  context?.node.input(value)
}

context?.node.on('prop', async ({ payload }) => {
  // refresh options if prop (which is used in apiEndpoint) has changed
  if (!context?.node.props.definition?.props?.includes(payload.prop)) {
    return
  }

  attrs.options = []
  await execute()
})

const inputRef = ref()
const onValueSelected = (event) => {
  if (props.type === 'single') {
    inputRef.value?.hide()
  }
}

const nextPageLoaded = ref(true);

const onListEnded = async () => {
  if (nextPageLoaded.value === false) {
    return
  }
  if (currentPageMeta.currentPage === currentPageMeta.lastPage) {
    return
  }

  // check if inputRef.value.querySelector('.p-dropdown-items-wrapper > ul') end is reached and load next page
  const listElement = document.querySelector('.p-dropdown-panel .p-dropdown-items-wrapper')
  if (!listElement) {
    console.log('listElement not found')
    return
  }

  const ulElement = listElement.querySelector('ul')
  if (!ulElement) {
    console.log('ulElement not found')
    return
  }

  const listElementHeight = ulElement.clientHeight
  const listElementScrollTop = listElement.scrollTop + listElement.clientHeight

  // console.log('listElement', listElement)
  console.log('listElementScrollTop', listElementHeight - listElementScrollTop)

  if ((listElementHeight - listElementScrollTop) <= 100) {
    console.log('load next page')
    nextPageLoaded.value = false;
    // load next page
    currentPageMeta.currentPage = currentPageMeta.currentPage + 1
    execute().then(() => {
      nextPageLoaded.value = true;
    });
  }
}

const apiDropdownRef = ref();

const registerListScrollListener = () => {
  if (!document) {
    console.log('document not found')
    return
  }

  const listElement = document.querySelector('.p-dropdown-panel .p-dropdown-items-wrapper')
  if (!listElement) {
    console.log('listElement not found')
    return
  }

  console.log('registerListScrollListener')

  listElement.addEventListener('scroll', onListEnded)
  nextPageLoaded.value = true;
}

const unregisterListScrollListener = () => {
  if (!document) {
    return
  }
  const listElement = document.querySelector('.p-dropdown-panel .p-dropdown-items-wrapper')
  if (!listElement) {
    return
  }

  listElement.removeEventListener('scroll', onListEnded)
}

const onHide = () => {
  showCreate.value = false
  unregisterListScrollListener()
}

onMounted(() => {
  addContextInputValues()

  if (preloadOptions) {
     execute()
  }
})
</script>

<template>
  <div ref="apiDropdownRef">
    <component
        ref="inputRef"
      :is="props.type === 'multi' ? MultiSelect : Dropdown"
      :selectionMode="props.type"
      :loading="isLoading"
      v-model="context._value"
      :input-id="context.id"
      :disabled="attrs._disabled ?? false"
      :readonly="attrs._readonly ?? false"
      :input-class="styleClass"
      :style-class="attrs.styleClass"
      :tabindex="attrs.tabindex"
      :aria-label="attrs.ariaLabel"
      :aria-labelledby="attrs.ariaLabelledby"
      :options="attrs.options"
      :option-label="attrs.optionLabel ?? 'label'"
      :option-value="attrs.optionValue ?? 'value'"
      :placeholder="placeholder"
      :filter="showFilter ?? attrs.filter ?? false"
      :show-clear="context?.showClear ?? attrs.showClear ?? false"
      :pt="attrs.pt"
      :pt-options="attrs.ptOptions"
      :unstyled="attrs.unstyled ?? false"
      @change="handleInput"
      @blur="handleBlur"
      @hide="onHide"
      @before-show="onBeforeShow"
      @update:model-value="onValueSelected"
      @show="registerListScrollListener"
    >
      <template #header>
        <component
          v-if="headerComponent && showHeader"
          :is="headerComponent"
          v-bind="headerComponentProps"
          :refresh="refresh"
          :set-input-value="setInput"
          v-model="showCreate"
        />
      </template>
      <template #option="slotProps" v-if="!!$slots.option">
        <slot name="option" v-bind="slotProps" :refresh="refresh" />
      </template>
      <template #value="slotProps" v-if="!!$slots.value">
        <slot name="value" v-bind="slotProps" />
      </template>

      <template #footer>
        <div v-if="!nextPageLoaded" class="justify-center mt-1 mb-2 flex gap-2 w-full items-center">
          <i class="pi pi-spinner pi-spin" /> Lade weitere Einträge...
        </div>
        <div v-if="nextPageLoaded && currentPageMeta.currentPage === currentPageMeta.lastPage" class="text-center mt-1 mb-2 text-sm">
          Alle Einträge wurden geladen
        </div>
      </template>
    </component>
  </div>
</template>
