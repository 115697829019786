<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext, FormKitFrameworkContext} from "@formkit/core";
import {useToast} from "primevue/usetoast";
import {useFetch} from "#app";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateItem from "~/components/AppDropdownCreateItem.vue";
import {AppPermissions} from "~/types/Permissions";
import {useUser} from "~/store/user";

const userStore = useUser();

const defaultUnit = computed(() => {
  return userStore.getDefaults?.unitId ?? undefined;
});

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const toast = useToast()
const defaultOption = ref(undefined)

const onSubmit = (data, { refresh, setInputValue }) => {
  useFetch('/api/v1/units', {
    method: 'POST',
    body: JSON.stringify({
      translations: {
        de: {
          name: data.name,
          locale: 'de'
        }
      }
    }),
    async onResponse({ response }): Promise<void> {
      if (response.status === 201) {
        await refresh()
        setInputValue(response._data.id)

        defaultOption.value = {
          ...response._data,
          label: response._data.name,
          value: response._data.id,
        }

        toast.add({
          severity: 'success',
          summary: 'Einheit hinzugefügt',
          detail: `Die Einheit "${response._data.name}" wurde erfolgreich hinzugefügt.`,
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Die Einheit konnte nicht hinzugefügt werden.`,
          life: 3500,
        })
      }
    },
    onResponseError() {
      toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Die Einheit konnte nicht hinzugefügt werden.`,
        life: 3500,
      })
    }
  })
}

</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :header-component="AppDropdownCreateItem"
      :header-component-props="{
        title: 'Neue Einheit hinzufügen',
        onSubmit
      }"
      :api-endpoint="'/api/v1/units'"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.name,
          value: data.id,
        }
      }"
      :preload-options="true"
      :default-option="defaultOption"
      :default-value="defaultUnit"
      default-field="id"
      :preselect-option="true"
      :default-placeholder="'Einheit auswählen'"
      :header-component-permissions="[AppPermissions.UnitWrite]"
    />
  </div>
</template>
