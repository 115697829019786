import { defineStore } from 'pinia';
import { useApiDropdown } from '~/store/api-dropdown';

export const useProduct = defineStore('product', {
  state: () => ({
    items: [],
  }),
  actions: {
    async addProduct(item) {
      if (!item) {
        return;
      }

      const index = this.items.findIndex((c) => c.id === item.id);
      if (index === -1) {
        this.items.push(item);
      } else {
        this.items[index] = item;
      }
    },
    async fetchProduct(id) {
      const item = await $fetch('/api/v1/catalogue/products/' + id);

      if (!item) {
        return;
      }

      useApiDropdown().addItem(item);
      this.addProduct(item);

      return item;
    },
  },
  getters: {
    getById: (state) => (id) => {
      return state.items.find((c) => c.id === id) || null;
    },
  },
});
