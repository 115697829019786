import {Ref} from "@vue/reactivity";

class Permissions {
  private permissions: Ref<string[]>;
  constructor() {
    this.permissions = useState('permissions', () => []);
  }

  private async fetchPermissions(force = false) {
    if (!this.permissions.value?.length || force) {
      if (process.server) {
        return;
      }
      const response = await fetch('/api/permissions', {
        method: 'GET',
      }).then((res) => res.json());

      const data = response.data;

      if (!data?.permissions) return;
      // @ts-ignore
      this.permissions.value = data.permissions;
    }
  }

  public async refreshPermissions(permissions: string[] | undefined) {
    if (permissions) {
      this.permissions.value = permissions;

      return;
    }
    await this.fetchPermissions(true);
  }

  public hasPermission(permission: string) {
    return this.permissions.value?.includes(permission);
  }

  public hasAnyPermission(permissions: string[] | string) {
    if (!Array.isArray(permissions) && typeof permissions !== 'string') {
      throw new TypeError('Permissions must be an array or string');
    }

    if (typeof permissions === 'string') {
      return this.hasPermission(permissions);
    }

    const result = permissions.some((permission) => this.hasPermission(permission));

    return result;
  }

  public hasAllPermission(permissions: string[]) {
    if (!Array.isArray(permissions)) {
      throw new TypeError('Permissions must be an array');
    }

    const result = permissions.every((permission) => this.hasPermission(permission));

    return result;
  }

  public hasNotPermission(permission: string) {
    return !this.hasPermission(permission);
  }
}

export const usePermissions = () => {
  const permissionsService = new Permissions();

  const can = (permission: string) => {
    return permissionsService.hasPermission(permission);
  };

  const canAny = (permissions: string[]) => {
    return permissionsService.hasAnyPermission(permissions);
  };

  const canAll = (permissions: string[]) => {
    return permissionsService.hasAllPermission(permissions);
  };

  const canNot = (permission: string) => {
    return permissionsService.hasNotPermission(permission);
  };

  const inDevelopment = () => {
    return false;
    return process.env.NODE_ENV === 'development';
  };

  const refreshPermissions = async (permissions: string[] | undefined) => {
    await permissionsService.refreshPermissions(permissions);
  };

  return {
    can,
    canAny,
    canAll,
    canNot,
    inDevelopment,
    refreshPermissions,
  };
};
