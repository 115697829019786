<script setup lang="ts">
import {FormKitSchemaDefinition} from "@formkit/core";
import Button from "primevue/button";
import {$fetch} from "ofetch";
import {useToast} from "primevue/usetoast";
import {useFlag} from "~/plugins/flagsmith";

const toast = useToast()
const dialogRef = inject('dialogRef')
const emit = defineEmits(['onSuccess'])

const library = markRaw({
  Button: Button
})

const usageBasedFeatureFlag = useFlag('billing.usage_based')
const advancedAggregationTypesFlag = useFlag('billing.advanced_aggregation_types');
const instantMeteredFeatureFlag = useFlag('billing.instant_metered');

const aggregationTypes = [
  { label: 'Summe aller gemeldeten Werte', value: 'sum' },
  { label: 'Zuletzt gemeldeter Wert', value: 'last_value' },
]

const typeOptions = [
  { label: 'Wiederkehrende Berechnung', value: 'recurring' },
]

if (usageBasedFeatureFlag.value) {
  typeOptions.push({ label: 'Nutzungsbasiert', value: 'metered' });
}

if (advancedAggregationTypesFlag.value) {
  aggregationTypes.push(...[
    { label: 'Maximum aller gemeldeten Werte', value: 'max' },
    { label: 'Menge der gemeldeten Werte', value: 'count' },
    { label: 'Eindeutige Menge der gemeldeten Werte', value: 'count_unique' },
  ])
}

if (instantMeteredFeatureFlag.value) {
  typeOptions.push({ label: 'Sofortige nutzungsbasierte Abrechnung', value: 'instant_metered' });
}

const formSchema: FormKitSchemaDefinition = reactive({
  $formkit: 'form',
  actions: false,
  children: [
    {
      $formkit: 'unitDropdown',
      name: 'unit',
      label: 'Einheit',
      validation: 'required',
      help: 'Die Einheit, in der die Messwerte erfasst werden.',
    },
    {
      $formkit: 'primeInputText',
      name: 'code',
      label: 'Code',
      validation: 'required',
      help: 'Eindeutiger Code, der zum Identifizieren der Messwerte verwendet wird.',
    },
    // description
    {
      $formkit: 'primeTextarea',
      name: 'description',
      label: 'Beschreibung',
      help: 'Eine kurze Beschreibung, die den Messwert näher beschreibt. Diese Beschreibung wird in der Verbrauchszusammenfassung auf der Rechnung angezeigt.',
    },
    // aggregationType
    {
      $formkit: 'primeDropdown',
      name: 'aggregationType',
      label: 'Aggregationstyp',
      validation: 'required',
      help: 'Der Aggregationstyp bestimmt, wie die Messwerte aggregiert werden.',
      options: aggregationTypes,
      value: 'last_value'
    },
    // type
    {
      $formkit: 'primeDropdown',
      name: 'type',
      label: 'Typ',
      value: 'recurring',
      validation: 'required',
      options: typeOptions,
      help: 'Der Typ bestimmt, wie die Messwerte abgerechnet werden.',
    },
    // button
    {
      $cmp: 'Button',
      props: {
        type: 'submit',
        label: 'Abrechenbare Einheit hinzufügen',
        icon: 'pi pi-plus',
        class: 'w-full mt-4 !-mb-2'
      }
    }
  ]
})

const onSubmit = async (data: any) => {
  console.log('onSubmit', data)

  try {
    const result = await $fetch('/api/v1/catalogue/measurements', {
      method: 'POST',
      body: data
    })

    toast.add({
      severity: 'success',
      summary: 'Abrechenbare Einheit hinzugefügt',
      detail: `Die abrechenbare Einheit "${result.code}" wurde erfolgreich hinzugefügt.`,
      life: 3500
    })

    dialogRef?.value?.close(result)
    emit('onSuccess', result)
  } catch {
    toast.add({
      severity: 'error',
      summary: 'Fehler',
      detail: `Die abrechenbare Einheit konnte nicht hinzugefügt werden.`,
      life: 3500,
    })
  } finally {
  }
}
</script>

<template>
  <div class="max-w-[400px]">
    <FormKitSchema :schema="formSchema" @submit="onSubmit" :library="library" />
  </div>
</template>

<style scoped lang="scss">

</style>
