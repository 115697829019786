import { FormKitSchemaDefinition } from '@formkit/core';

const customerCountryCodeSchema = {
  $formkit: 'countryDropdown',
  name: 'countryCode',
  label: 'Land',
  filter: true,
  validation: 'required',
  value: 'DE',
};

const customerVatIdSchema = {
  $formkit: 'primeInputText',
  name: 'vatId',
  label: 'USt-IdNr.',
  help: 'Die USt-IdNr. des Kunden, dient zur Ausstellung von Rechnungen ohne Umsatzsteuer.',
  placeholder: 'DE123456789',
  nullable: true,
  //validation: 'vatId',
};

const customerContactDataFieldSetSchema = {
  $cmp: 'Fieldset',
  props: {
    legend: 'Kontaktdaten',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    {
      $formkit: 'primeInputMask',
      name: 'phone',
      mask: '+99 999 99999999',
      label: 'Telefon',
      placeholder: '+49 123 456789',
      nullable: true,
    },
  ],
};

const customerGroupFieldSchema = {
  $formkit: 'customerGroupDropdown',
  name: 'customerGroup',
  label: 'Kundengruppe',
  help: 'Die Kundengruppe dient zur Filterung und Zuordnung von Kunden.',
  nullable: true,
  showClear: true,
};

const contactPersonFieldSchema = {
  $formkit: 'contactPersonDropdown',
  name: 'contactPerson',
  label: 'Ansprechpartner',
  nullable: true,
  showClear: true,
};

const taxExemptDropdownSchema = {
  $formkit: 'primeDropdown',
  name: 'taxExempt',
  value: 'auto',
  label: 'Steuerbefreiung',
  options: [
    {
      label: 'Automatisch ermitteln',
      value: 'auto',
    },
    {
      label: 'Steuerbefreit',
      value: 'exempt',
    },
  ],
  help: 'Der Steuersatz wird auf Basis des Landes und der USt-IdNr. automatisch ermittelt (inkl. Reverse Charge). In einigen Fällen ist es notwendig, den Kunden von der Steuer zu befreien.',
}

const customerBaseDataSchema = [
  {
    $formkit: 'primeDropdown',
    name: 'customerType',
    label: 'Kundentyp',
    options: [
      {
        label: 'Privatkunde',
        value: 'private',
      },
      {
        label: 'Einzelunternehmer',
        value: 'soleProprietor',
      },
      {
        label: 'Geschäftskunde',
        value: 'business',
      },
    ],
    validation: 'required',
    value: 'private',
  },
  {
    $formkit: 'primeInputText',
    if: '$value.customerType === "business"',
    name: 'companyName',
    key: 'companyName',
    label: 'Unternehmen',
    validation: 'required',
    placeholder: 'ACME GmbH',
    nullable: true,
  },
  {
    $el: 'div',
    attrs: {
      class: 'md:grid md:grid-cols-2 md:gap-3',
    },
    children: [
      {
        $formkit: 'primeInputText',
        name: 'firstName',
        label: {
          if: '$value.customerType !== "business"',
          then: 'Vorname*',
          else: 'Ansprechpartner Vorname',
        },
        validation: {
          if: '$value.customerType === "personal" || $value.customerType === "soleProprietor"',
          then: 'required',
        },
        placeholder: 'Max',
        nullable: true,
      },
      {
        $formkit: 'primeInputText',
        name: 'lastName',
        label: {
          if: '$value.customerType !== "business"',
          then: 'Nachname*',
          else: 'Nachname',
        },
        validation: {
          if: '$value.customerType === "personal" || $value.customerType === "soleProprietor"',
          then: 'required',
        },
        placeholder: 'Mustermann',
        nullable: true,
      },
    ],
  },
];

const customerGenericSchema = [
  {
    $el: 'div',
    attrs: {
      class: 'md:grid md:grid-cols-2 md:gap-3',
    },
    children: [
      customerCountryCodeSchema,
      {
        $formkit: 'currencyDropdown',
        returnCode: true,
        name: 'currencyCode',
        label: 'Währung',
        preselectOption: true,
        validation: 'required',
      },
      {
        $formkit: 'languageDropdown',
        name: 'language',
        label: 'Sprache',
        validation: 'required',
        value: 'de',
      },
      {
        $formkit: 'timeZoneDropdown',
        name: 'timeZone',
        label: 'Zeitzone',
        validation: 'required',
        value: 'Europe/Berlin',
      },
    ],
  },
];

const customerAddressBaseSchema = [
  {
    $el: 'div',
    attrs: {
      class: 'md:grid md:grid-cols-4 md:gap-3',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'md:col-span-3',
        },
        children: [
          {
            $formkit: 'primeInputText', // todo: googleAddressAutocomplete
            apiKey: 'AIzaSyDJpaQL5cyGh1uROH43vGAavlXokpOb9zw',
            streetNumberField: 'houseNumber',
            zipField: 'zip',
            cityField: 'city',
            countryCodeField: 'countryCode',
            name: 'street',
            label: 'Straße',
            nullable: true,
            attrs: {
              class: 'md:col-span-3',
            },
            placeholder: 'Musterstraße',
          },
        ],
      },
      {
        $formkit: 'primeInputText',
        name: 'houseNumber',
        label: 'Hausnummer',
        placeholder: '1a',
        nullable: true,
      },
    ],
  },
  {
    $formkit: 'primeInputText',
    name: 'addition',
    label: 'Adresszusatz',
    placeholder: 'Abteilung 1',
    nullable: true,
  },
  {
    $el: 'div',
    attrs: {
      class: 'md:grid md:grid-cols-4 md:gap-3',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'md:col-span-1',
        },
        children: [
          {
            $formkit: 'primeInputText',
            name: 'zip',
            label: 'PLZ',
            placeholder: '12345',
            nullable: {
              if: '$value.city',
              then: false,
              else: true,
            },
          },
        ],
      },
      {
        $el: 'div',
        attrs: {
          class: 'md:col-span-3',
        },
        children: [
          {
            $formkit: 'primeInputText',
            name: 'city',
            label: 'Ort',
            placeholder: 'Musterstadt',
            nullable: true,
          },
        ],
      },
    ],
  },
];

const customerAddressSchema: FormKitSchemaDefinition[] = [
  ...customerBaseDataSchema,
  ...customerAddressBaseSchema,
  customerCountryCodeSchema,
  {
    $formkit: 'hidden',
    name: 'costCentre',
    value: null,
  },
];

export {
  customerBaseDataSchema,
  customerAddressSchema,
  customerCountryCodeSchema,
  customerVatIdSchema,
  customerAddressBaseSchema,
  customerGenericSchema,
  customerContactDataFieldSetSchema,
  customerGroupFieldSchema,
  contactPersonFieldSchema,
  taxExemptDropdownSchema,
};
