<script setup lang="ts">
import Turnstile from 'cfturnstile-vue3';
import { PropType } from 'vue';
import { FormKitFrameworkContext } from '@formkit/core';

const { turnstileSiteKey } = useRuntimeConfig().public;

const props = defineProps({
  context: {
    type: Object as PropType<FormKitFrameworkContext>,
    required: true,
  },
});

function handleInput(token: any) {
  props.context.node.input(token);
}

const onVerify = (token: any) => {
  handleInput(token);
};
</script>

<template>
  <Turnstile :sitekey="turnstileSiteKey" @verify="onVerify" />
</template>

<style scoped lang="scss"></style>
