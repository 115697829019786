<script lang="ts" setup>
import {ca} from "@formkit/i18n";

const props = defineProps({
  onSubmit: {
    type: Function,
    required: true
  },
  // allow to override schema for input field
  fieldSchema: {
    type: Object,
    required: false,
    default: null
  }
})

const isLoading = ref(false)

const emit = defineEmits(['onSuccess'])
const onSubmit = async (data: any) => {
  isLoading.value = true

  try {
    const submitData = await props.onSubmit(data)

    emit('onSuccess', {
      formData: data,
      data: submitData
    })
  } catch (e) {
    // do nothing
  }

  isLoading.value = false
}

const formSchema = reactive([
  {
    $el: 'div',
    attrs: {
      class: 'flex flex-row gap-1',
    },
    children: [
      props.fieldSchema ?? {
        $formkit: 'primeInputText',
        name: 'name',
        placeholder: 'Name',
        classes: {
          outer: 'flex-1',
        },
        props: {
          disabled: isLoading,
        },
      },
      {
        $cmp: 'Button',
        props: {
          type: 'submit',
          loading: isLoading,
          icon: 'pi pi-fw pi-check',
          click: onSubmit
        },
        // children: [
        //   {
        //     $el: 'i',
        //     attrs: {
        //       class: 'pi pi-fw pi-check'
        //     }
        //   }
        // ]
      }
    ]
  }
])
</script>
<template>
  <div>
    <FormKit type="form" :actions="false" @submit="onSubmit" :disabled="isLoading">
      <FormKitSchema :schema="formSchema"></FormKitSchema>
    </FormKit>
  </div>
</template>
