import { createMessage, FormKitNode } from '@formkit/core';

/**
 * A contrived validation rule that checks if the value is a valid iban.
 */
const iban = async function (node: FormKitNode) {
  node.store.remove('rule_iban_invalid');
  node.store.remove('rule_iban_api_error');
  node.context.iconRight = '';

  if (!node.value && node.props.validationRules?.required) {
    return false;
  }

  if (!node.value) {
    return true;
  }

  const validatingIbanMessage = createMessage({
    key: 'rule_iban_validating',
    value: 'Die angegebene IBAN wird überprüft...',
    visible: true,
    blocking: true,
    type: 'loading',
  });

  node.store.set(validatingIbanMessage);

  node.context.iconRight = 'pi pi-spin pi-spinner';

  const bicField = node.props?.attrs?.bicField || false;
  const bankNameField = node.props?.attrs?.bankNameField || false;
  const bicNode = node.parent?.find(bicField);
  const bankNameNode = node.parent?.find(bankNameField);

  const currentHelpText = node.props.help || '';

  const toggleFieldVisibility = (isDisabled: boolean, emptyBankData = false) => {
    node.props.disabled = isDisabled;
    if (bicNode) {
      bicNode.props.disabled = isDisabled;
      if (emptyBankData) {
        bicNode.input('');
      }
    }
    if (bankNameNode) {
      bankNameNode.props.disabled = isDisabled;
      if (emptyBankData) {
        bankNameNode.input('');
      }
    }
  };

  // set all fields disabled until iban is validated
  toggleFieldVisibility(true);

  const { data, status } = await useFetch('/api/v1/payment/validate-iban', {
    method: 'POST',
    body: JSON.stringify({
      iban: node.value,
      getBic: true,
    }),
  });

  node.store.remove('rule_iban_validating');

  node.props.help = currentHelpText;

  toggleFieldVisibility(false);

  node.context.iconRight = '';

  if (status.value !== 'success') {
    node.context.iconRight = 'pi pi-exclamation-triangle p-invalid-icon';
    const apiErrorMessage = createMessage({
      key: 'rule_iban_api_error',
      value: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      visible: true,
      blocking: true,
      type: 'validation',
    });

    node.store.set(apiErrorMessage);

    return;
  }

  if (!data.value.valid) {
    node.context.iconRight = 'pi pi-times p-invalid-icon';
    toggleFieldVisibility(false, true);

    const invalidMessage = createMessage({
      key: 'rule_iban_invalid',
      value: 'Die angegebene IBAN ist ungültig.',
      visible: true,
      blocking: true,
      type: 'validation',
    });

    node.store.set(invalidMessage);
  } else {
    node.context.iconRight = 'pi pi-check p-valid-icon';

    if (bicNode && data.value?.bankData?.bic) {
      bicNode.input(data.value.bankData.bic);

      // if we're going to modify the schema then we need
      // to update the schemaMemoKey so we don't get an
      // invalid cached schema.
      // @ts-ignore
      bicNode.props.definition.schemaMemoKey = `disabled_${node.props.definition.schemaMemoKey}`;
      bicNode.props.disabled = true;
    }

    if (bankNameNode && data.value?.bankData?.name) {
      bankNameNode.input(data.value.bankData.name);

      // if we're going to modify the schema then we need
      // to update the schemaMemoKey so we don't get an
      // invalid cached schema.
      // @ts-ignore
      bankNameNode.props.definition.schemaMemoKey = `disabled_${node.props.definition.schemaMemoKey}`;
      bankNameNode.props.disabled = true;
    }
  }

  return true;
};

iban.blocking = true;
iban.skipEmpty = false;
iban.debounce = 20; // milliseconds
iban.force = true;

export default iban;
