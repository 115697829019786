import {ReturnType} from "birpc";
import {useConfirm} from "primevue/useconfirm";
import useModal from "~/composables/useModal";
import useMessage from "~/composables/useMessage";
import {PricePlan} from "~/types/billing";
import CheckoutLinkCreateModal from "~/components/CheckoutLink/CheckoutLinkCreateModal.vue";
import AppModalSuspense from "~/components/AppModalSuspense.vue";
import CheckoutLinkDetailModal from "~/components/CheckoutLink/CheckoutLinkDetailModal.vue";
import {Product} from "~/types/productSet";
import {Plan} from "~/types/plan";

export type CreateCheckoutLinkModalProps = {
  pricePlan: PricePlan;
  type: 'product' | 'plan';
  id: string;
};

export type CheckoutLinkItemProduct = {
  type: 'product';
  quantity: number;
  product: string | Product;
  periods: {
    contractPeriod: string;
    cancellationPeriod: string;
  }[];
};

export type CheckoutLinkItemPlan = {
  type: 'plan';
  quantity: number;
  plan: string | Plan;
};

export type CheckoutLinkItem = {
  type: 'product' | 'plan';
  price: string;
} & CheckoutLinkItemProduct | CheckoutLinkItemPlan;

export type CheckoutLink = {
  id?: string;
  url?: string;
  slug?: string;
  isEnabled?: boolean;
  items?: CheckoutLinkItem[];
}

export class CheckoutLinkService {
  private confirm: ReturnType<typeof useConfirm>;
  private modal: ReturnType<typeof useModal>;
  private message: ReturnType<typeof useMessage>;

  public constructor(
    confirm: ReturnType<typeof useConfirm>,
    modal: ReturnType<typeof useModal>,
    message: ReturnType<typeof useMessage>
  ) {
    this.confirm = confirm;
    this.modal = modal;
    this.message = message;
  }

  public openCheckoutLinkModal({ pricePlan, type, id, onClose }: CreateCheckoutLinkModalProps & { onClose?: (data: any) => void }) {
    const { open: openModal } = this.modal;
    const { success, error } = this.message;

    const open = () => {
      openModal(CheckoutLinkCreateModal, {
        data: {
          pricePlan,
          type,
          id,
        },
        props: {
          header: 'Checkout-Link erstellen',
        },
        onClose: (dialogProps) => {
          if (dialogProps?.type === 'config-close') {
            const loadingDialog = this.modal.loading('Checkout-Link wird erstellt...');

            const periods = dialogProps.data?.periods || [];
            delete dialogProps.data.periods;

            $fetch('/api/v1/checkout-links', {
              method: 'POST',
              body: {
                ...dialogProps.data,
                items: [
                  {
                    type,
                    quantity: 1,
                    plan: type === 'plan' ? id : undefined,
                    product: type === 'product' ? id : undefined,
                    price: pricePlan.id,
                    periods,
                  },
                ],
              },
              onResponse({ response }) {
                if (response.ok) {
                  success('Checkout Link wurde erstellt.');

                  onClose?.(response._data);
                } else {
                  error('Checkout Link konnte nicht erstellt werden.');
                }

                loadingDialog.close();
              }
            })
          }
        },
      });
    };

    open();
  }

  public async updateCheckoutLink(linkId: string, data: CheckoutLink, onSuccess?: (checkoutLink: any) => void, onError?: (responseData: any) => void): Promise<CheckoutLink> {
    const result = await $fetch(`/api/v1/checkout-links/${linkId}`, {
      method: 'PUT',
      body: data,
      onResponse: ({ response }) => {
        if (response.ok) {
          onSuccess?.(response._data);
          this.message.success('Checkout-Link wurde aktualisiert.')
        } else {
          onError?.(response._data);

          this.message.error('Checkout-Link konnte nicht aktualisiert werden.');
        }
      },
    });

    return result;
  }

  public showCheckoutLink(linkId: string, onSuccess?: (checkoutLink: any) => void, onError?: (responseData: any) => void) {
    this.modal.open(AppModalSuspense, {
      props: {
        header: 'Checkout-Link bearbeiten',
        style: {
          maxWidth: '430px',
        },
      },
      data: {
        component: CheckoutLinkDetailModal,
        linkId,
        onSuccess,
        onError,
      },
      onClose: (dialogProps) => {
        if (dialogProps?.type === 'config-close') {
          // do nothing..
        }
      },
    });
  }
}
