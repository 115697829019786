<script setup lang="ts">
import {nextTick, PropType} from "vue";
import {FormKitContext, FormKitFrameworkContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import {useDialog} from "primevue/usedialog";
import CreateCustomerForm from "~/components/Customer/CreateCustomerForm.vue";
import {AppPermissions} from "~/types/Permissions";
import CreateCustomerGroupForm from "~/components/CustomerGroup/CreateCustomerGroupForm.vue";

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext>
})

const dialog = useModal();

props.context.attrs.filter = true

const apiEndpoint = computed(() => {
  return '/api/v1/ui/dropdown/customer-groups'
})

const headerComponentProps = {
  title: 'Kundengruppe erstellen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(CreateCustomerGroupForm, {
      props: {
        header: 'Kundengruppe erstellen',
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await refresh()
          setInputValue(data.data.id)
        }
      }
    })
  }
};

</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="apiEndpoint"
      :show-filter="true"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.label,
          value: data.value,
        }
      }"
      :default-placeholder="'Kundengruppe auswählen'"
      :preload-options="true"
      :header-component="AppDropdownCreateButton"
      :header-component-props="headerComponentProps"
      :header-component-permissions="[AppPermissions.CustomerGroupWrite]"
    />
  </div>
</template>
