<script setup lang="ts">
import {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
import {Ref} from "@vue/reactivity";
import {CreateCheckoutLinkModalProps} from "~/services/CheckoutLinkService";
import CheckoutLinkFormFields from "~/components/CheckoutLink/CheckoutLinkFormFields.vue";

const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef');
const props: CreateCheckoutLinkModalProps = dialogRef?.value.data;

const onSubmit = (form: any) => {
  const formData = {
    ...form,
    afterCheckout: {
      ...form.afterCheckout,
      action: form.afterCheckout.actionType === 'custom_confirmation_message' ? 'confirmation_message' : form.afterCheckout.actionType,
    },
  };

  delete formData.afterCheckout.actionType;

  dialogRef?.value.close(formData);
};
</script>

<template>
  <div class="min-w-[400px] max-w-[400px]">
    <FormKit type="form" #default="{ value }" :actions="false" @submit="onSubmit">
      <CheckoutLinkFormFields :form-value="value" />

      <FormKit type="submitButton" create-label="Checkout-Link erstellen" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
