<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext, FormKitFrameworkContext} from "@formkit/core";
import {useToast} from "primevue/usetoast";
import {useFetch} from "#app";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import AppDropdownCreateItem from "~/components/AppDropdownCreateItem.vue";
import {AppPermissions} from "~/types/Permissions";

const props = defineProps({
  context: Object as PropType<FormKitContext & {
    templateType: 'page' | 'section'
  }>
})

const toast = useToast()
const defaultOption = ref(undefined)

const onSubmit = (data, { refresh, setInputValue }) => {
  useFetch('/api/v1/offer-templates', {
    method: 'POST',
    body: JSON.stringify({
      name: data.name,
      type: props.context?.templateType ?? 'section'
    }),
    async onResponse({ response }): Promise<void> {
      if (response.status === 201) {
        await refresh()
        setInputValue(response._data.id)

        defaultOption.value = {
          ...response._data,
          label: response._data.name,
          value: response._data.id,
        }

        toast.add({
          severity: 'success',
          summary: 'Vorlage hinzugefügt',
          detail: `Die Vorlage "${response._data.name}" wurde erfolgreich hinzugefügt.`,
          life: 3500,
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Die Vorlage konnte nicht hinzugefügt werden.`,
          life: 3500,
        })
      }
    },
    onResponseError() {
      toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: `Die Vorlage konnte nicht hinzugefügt werden.`,
        life: 3500,
      })
    }
  })
}

</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :header-component="AppDropdownCreateItem"
      :header-component-props="{
        title: 'Neue Vorlage hinzufügen',
        onSubmit
      }"
      :api-endpoint="'/api/v1/offer-templates?type=' + (context?.templateType ?? 'section')"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.name,
          value: data.id,
        }
      }"
      :preload-options="true"
      :default-option="defaultOption"
      :default-placeholder="'Vorlage auswählen'"
      :header-component-permissions="[AppPermissions.OfferWrite]"
    />
  </div>
</template>
