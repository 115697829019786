export default class PaymentMethodService {
  getLabel(type: string) {
    switch (type) {
      case 'card':
        return 'Kreditkarte';
      case 'paypal':
        return 'PayPal';
      case 'sepa_debit':
        return 'SEPA-Lastschrift';
      case 'bank_transfer':
        return 'Überweisung';
      case 'wallet':
        return 'Guthaben';
      default:
        return type;
    }
  }

  getIcon(type: string) {
    switch (type) {
      case 'card':
        return 'credit-card';
      case 'paypal':
        return 'paypal';
      case 'sepa_debit':
        return 'send';
      case 'bank_transfer':
        return 'euro';
      case 'wallet':
        return 'wallet';
      default:
        return type;
    }
  }
}
