

const languageFlagsUnicode = {
  'de': '🇩🇪',
  'en': '🇬🇧',
  'fr': '🇫🇷',
  'it': '🇮🇹',
  'es': '🇪🇸',
  'pt': '🇵🇹',
  'nl': '🇳🇱',
  'pl': '🇵🇱',
  'ru': '🇷🇺',
  'tr': '🇹🇷',
  'zh': '🇨🇳',
  'ja': '🇯🇵',
  'ko': '🇰🇷',
  'ar': '🇸🇦',
  'hi': '🇮🇳',
  'id': '🇮🇩',
  'th': '🇹🇭',
  'vi': '🇻🇳',
  'sv': '🇸🇪',
  'da': '🇩🇰',
  'fi': '🇫🇮',
  'no': '🇳🇴',
  'cs': '🇨🇿',
  'hu': '🇭🇺',
  'el': '🇬🇷',
  'ro': '🇷🇴',
  'sk': '🇸🇰',
  'bg': '🇧🇬',
  'uk': '🇺🇦',
  'hr': '🇭🇷',
  'sr': '🇷🇸',
  'sl': '🇸🇮',
  'et': '🇪🇪',
  'lv': '🇱🇻',
  'lt': '🇱🇹',
  'ms': '🇲🇾',
  'fa': '🇮🇷',
  'he': '🇮🇱',
  'ur': '🇵🇰',
  'bn': '🇧🇩',
  'ca': '🇪🇸',
  'eu': '🇪🇸',
  'gb': '🇬🇧',
}

const languageToCountry = {
  'de': 'de',
  'en': 'gb',
  'fr': 'fr',
  'it': 'it',
  'es': 'es',
  'pt': 'pt',
  'nl': 'nl',
  'pl': 'pl',
  'ru': 'ru',
  'tr': 'tr',
  'zh': 'cn',
  'ja': 'jp',
  'ko': 'kr',
  'ar': 'sa',
  'hi': 'in',
  'id': 'id',
  'th': 'th',
  'vi': 'vn',
  'sv': 'se',
  'da': 'dk',
  'fi': 'fi',
  'no': 'no',
  'cs': 'cz',
  'hu': 'hu',
  'el': 'gr',
  'ro': 'ro',
  'sk': 'sk',
  'bg': 'bg',
  'uk': 'ua',
  'hr': 'hr',
  'sr': 'rs',
  'sl': 'si',
  'et': 'ee',
  'lv': 'lv',
  'lt': 'lt',
  'ms': 'my',
  'fa': 'ir',
  'he': 'il',
  'ur': 'pk',
  'bn': 'bd',
  'ca': 'es',
  'eu': 'es',
}

export const getUnicodeFlag = (countryCode: string): string => {
  return languageFlagsUnicode[countryCode] || '';
}

export const getUnicodeLanguageFlag = (languageCode: string): string => {
  return getUnicodeFlag(languageToCountry[languageCode]);
}
