import useModal from "~/composables/useModal";
import {ReturnType} from "birpc";
import {useConfirm} from "primevue/useconfirm";
import useMessage from "~/composables/useMessage";
import {setIn} from "immutable";
import {PricePlan} from "~/types/billing";

export const BillingIntervalOptions = [
  // {label: 'Einmalig', value: 'one_time'}, - not required because we have now a recurring / non-recurring switcher
  {label: 'Täglich', value: '1D'},
  {label: 'Wöchentlich', value: '1W'},
  {label: 'Monatlich', value: '1M'},
  {label: 'Vierteljährlich', value: '3M'},
  {label: 'Halbjährlich', value: '6M'},
  {label: 'Jährlich', value: '1Y'},
];

export const createEmptyPricePlan = (productId: string) => {
  return {
    currencyCode: 'EUR',
    billingInterval: '1M',
    payInAdvance: true,
    internalName: null,
    status: 'active',
    type: 'per_unit',
    applyTrial: false,
    freeUnits: null,
    price: {
      amount: 0,
    },
    product: productId,
    excludeFromGlobalPriceUpdates: false,
  };
};

export const addPriceSchema = reactive([
  {
    $formkit: 'primeDropdown',
    name: 'billingInterval',
    label: 'Abrechnungsinterval',
    options: BillingIntervalOptions,
    value: '1M',
    validation: 'required',
  },
  {
    $formkit: 'currencyDropdown',
    name: 'currency',
    label: 'Währung',
    validation: 'required',
    preselectOption: true,
    returnCode: true,
  },
]);

export class PriceNotArchivedException extends Error {
  public constructor() {
    super('Price could not be archived.');
  }
}

export class PriceNotRemovedException extends Error {
  public constructor() {
    super('Price could not be removed.');
  }
}

export default class PricePlanService {
  private confirm: ReturnType<typeof useConfirm>
  private modal: ReturnType<typeof useModal>
  private message: ReturnType<typeof useMessage>

  public constructor(
    confirm: ReturnType<typeof useConfirm>,
    modal: ReturnType<typeof useModal>,
    message: ReturnType<typeof useMessage>
  ) {
    this.confirm = confirm
    this.modal = modal
    this.message = message
  }

  public async createPricePlan(pricePlan: PricePlan) {
    const response = await $fetch<PricePlan>('/api/v1/price-plans', {
      method: 'POST',
      body: pricePlan,
      onResponse: ({ response }) => {
        if (response.status === 201) {
          this.message.success('Der Preis wurde erfolgreich erstellt.');
        }
      },
    }).catch((error) => {
      this.message.error('Der Preis konnte nicht erstellt werden.');

      return error.data;
    });

    return response;
  }

  public async updatePricePlan(pricePlan: PricePlan) {
    if (!pricePlan?.id) {
      throw new Error('Price plan has no id. Cannot update.');
    }

    const response = await $fetch<PricePlan>(`/api/v1/price-plans/${pricePlan.id}`, {
      method: 'PUT',
      body: pricePlan,
      onResponse: ({ response }) => {
        if (response.status === 200) {
          this.message.success('Der Preis wurde erfolgreich aktualisiert.');
        }
      },
    }).catch((error) => {
      this.message.error('Der Preis konnte nicht aktualisiert werden.');

      return error.data;
    });

    return response;
  };

  public archivePrice(pricePlan: any, afterAccept?: Function) {
    this.confirm.require({
      //target: event.currentTarget,
      header: 'Preis archivieren',
      message: 'Möchtest du diesen Preis wirklich archivieren? Dieser Preis wird nicht mehr in der Preisübersicht angezeigt.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Archivieren',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
      accept: () => {
        const loading = this.modal.loading('Preis wird archiviert...')

        if (!pricePlan.id) {
          this.message.success('Der Preis wurde erfolgreich archiviert.')
          loading.close()
          return
        }

        $fetch(`/api/v1/price-plans/${pricePlan.id}/archive`, {
          method: 'PUT',
          onResponse: ({response}) => {
            if (response.status === 200) {
              this.message.success('Der Preis wurde erfolgreich archiviert.')
              if (afterAccept) {
                afterAccept(pricePlan)
              }
            } else {
              this.message.error('Der Preis konnte nicht archiviert werden.')
              throw new PriceNotArchivedException();
            }

            loading.close()
          }
        })
      },
      rejectLabel: 'Abbrechen',
      rejectIcon: 'pi pi-times',
      reject: () => {
      }
    })
  }

  removePrice(pricePlan: any, afterAccept?: Function) {
    this.confirm.require({
      //target: event.currentTarget,
      header: 'Preis löschen',
      message: 'Möchtest du diesen Preis wirklich löschen? Dieser Preis wird nicht mehr in der Preisübersicht angezeigt.',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Löschen',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
      accept: () => {
        const loading = this.modal.loading('Preis wird gelöscht...')

        if (!pricePlan.id) {
          this.message.success('Der Preis wurde erfolgreich gelöscht.')
          loading.close()
          return
        }

        $fetch(`/api/v1/price-plans/${pricePlan.id}`, {
          method: 'DELETE',
          onResponse: ({response}) => {
            if (response.status === 204) {
              this.message.success('Der Preis wurde erfolgreich gelöscht.')
              if (afterAccept) {
                afterAccept(pricePlan)
              }
            } else {
              this.message.error('Der Preis konnte nicht gelöscht werden.')
            }

            loading.close()
          }
        })
      },
      rejectLabel: 'Abbrechen',
      rejectIcon: 'pi pi-times',
      reject: () => {
      }
    })
  }
}
