<script setup lang="ts">
import {CostCentreService} from "~/services/CostCentreService";
import {useConfirm} from "primevue/useconfirm";
import useModal from "~/composables/useModal";
import useMessage from "~/composables/useMessage";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['created', 'updated'])

const costCentreService = new CostCentreService(useConfirm(),useModal(), useMessage());

const onSubmit = async (form: any) => {
  form.status = form.isArchived ? 'archived' : 'active';
  delete form.isArchived;
  if (form.id) {
    await costCentreService.updateCostCentre(form).then((costCentre) => {
      emit('updated', costCentre)

      return costCentre;
    });
  } else {
    await costCentreService.createCostCentre(form).then((costCentre) => {
      emit('created', costCentre)

      return costCentre;
    });
  }
}
</script>

<template>
  <FormKit type="form" :actions="false" @submit="onSubmit" #default="{ value }" :value="modelValue">
    <FormKit type="hidden" name="id" />
    <FormKit type="primeInputText" label="Kostenstelle" placeholder="Kostenstelle" name="code" :disabled="!!value.id" validation="required" />
    <FormKit type="primeInputText" name="name" validation="required" label="Name" placeholder="Name" help="Anzeigename" />
    <FormKit type="primeDropdown" name="type" label="Typ" validation="required" :options="[
      { label: 'KOST1', value: 'KOST1' },
      { label: 'KOST2', value: 'KOST2' },
    ]" placeholder="Bitte auswählen" />
    <div class="flex">
      <FormKit type="primeCheckbox" name="isArchived" label="Ausblenden" :model-value="value.status === 'archived'" v-if="!!value.id" />
    </div>

    <FormKit type="primeSubmitButton" :submit-label="value.id ? 'Kostenstelle aktualisieren' : 'Kostenstelle erstellen'" />
  </FormKit>
</template>

<style scoped lang="scss">

</style>
