import {createI18n} from "vue-i18n";
import { messages } from "vite-i18n-resources";

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = createI18n({
    legacy: false,
    locale: "de",
    fallbackLocale: "en",
    messages,
  });

  nuxtApp.vueApp.use(i18n);

  if (import.meta.hot) {
    import.meta.hot.on("locales-update", (data) => {
      Object.keys(data).forEach((lang) => {
        i18n.global.setLocaleMessage(lang, data[lang]);
      });
    });
  }
});
