<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import {useUser} from "~/store/user";

const userStore = useUser();

const defaultTaxGroup = computed(() => {
  return userStore.getDefaults?.taxGroupId ?? undefined;
});

const props = defineProps({
  context: Object as PropType<FormKitContext>
})
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="`/api/v1/tax-groups`"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.internalDescription,
          value: data.id,
        }
      }"
      :default-placeholder="'Steuergruppe auswählen'"
      :preload-options="true"
      :default-value="defaultTaxGroup"
      default-field="id"
      :preselect-option="true"
    />
  </div>
</template>
