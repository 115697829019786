import { useToast } from 'primevue/usetoast';
import {useI18n} from "vue-i18n";

export default function useMessage() {
  const toast = useToast();
  const { t } = useI18n();

  const getTranslationKey = (key: string, field: 'message' | 'type' | 'title') => {
    return `messages.${key}.${field}`;
  }

  const translationExists = (key: string, field: 'message' | 'type' | 'title') => {
    const translationKey = getTranslationKey(key, field);
    return t(translationKey) !== translationKey;
  }

  const getTranslatedMessage = (key: string) => {
    if (translationExists(key, 'message')) {
      return t(getTranslationKey(key, 'message'));
    }

    return key;
  }

  const getSeverity = (key: string): 'success' | 'info' | 'warn' | 'error' => {
    if (translationExists(key, 'type')) {
      return t(getTranslationKey(key, 'type')) as 'success' | 'info' | 'warn' | 'error';
    }

    return key as any;
  }

  return {
    show: (message: string, lifetime?: number) => {
      if (!translationExists(message, 'message')) {
        throw new Error(`No translation found for message ${message}`);
      }

      if (!translationExists(message, 'type')) {
        throw new Error(`No translation found for type ${message}`);
      }

      toast.add({
        summary: getSeverity(message),
        detail: getTranslatedMessage(message),
        severity: getSeverity(message),
        life: lifetime ?? 3500,
      });
    },
    success: (message: string, lifetime?: number) => {
      toast.add({
        summary: 'Erfolgreich',
        detail: getTranslatedMessage(message),
        severity: 'success',
        life: lifetime ?? 3500,
      });
    },
    error: (message: string, lifetime?: number) => {
      toast.add({
        summary: 'Fehler',
        detail: getTranslatedMessage(message),
        severity: 'error',
        life: lifetime ?? 3500,
      });
    },
    warning: (message: string, lifetime?: number) => {
      toast.add({
        summary: 'Warnung',
        detail: getTranslatedMessage(message),
        severity: 'warn',
        life: lifetime ?? 3500,
      });
    },
    notAvailableInPlan: () => {
      toast.add({
        summary: 'Nicht verfügbar',
        detail: 'Diese Funktion ist in deinem Plan nicht enthalten.',
        severity: 'warn',
        life: 3500,
      });
    }
  };
}
