import moment from 'moment';

export const isDst = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.min(jan.getTimezoneOffset(), jul.getTimezoneOffset()) === date.getTimezoneOffset();
};

export const formatDate = (date: string | Date, longMonth: boolean = false) => {
  if (!date) {
    return '';
  }

  date = typeof date === 'string' ? new Date(date) : date;

  if (isDst(new Date(date))) {
    date = moment(date).add(1, 'hours').toISOString();

    return new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: longMonth ? 'long' : 'short',
      day: 'numeric',
    }).format(moment(date).toDate());
  }

  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: longMonth ? 'long' : 'short',
    day: 'numeric',
  }).format(moment(date).toDate());
};

export const formatDateToNumeric = (date: string) => {
  if (!date) {
    return '';
  }

  if (isDst(new Date(date))) {
    date = moment(date).add(1, 'hours').toISOString();

    return new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(moment(date).toDate());
  }

  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(moment(date).toDate());
};

export const formatDateTimeToNumeric = (date: string) => {
  if (!date) {
    return '';
  }

  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(moment(date).toDate());
};

export const formatDateTimeToActivityTime = (date: string) => {
  if (!date) {
    return '';
  }

  return (
    new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(moment(date).toDate()) +
    ' um ' +
    new Intl.DateTimeFormat('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(moment(date).toDate())
  );
};

const dateToYmd = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateTime = (date: string) => {
  if (!date) {
    return '';
  }

  if (isDst(new Date(date))) {
    date = moment(date).add(1, 'hours').toISOString();

    return new Intl.DateTimeFormat('de-DE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(moment(date).toDate());
  }

  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(moment(date).toDate());
};

export const formatCurrency = (amount: number, currency: string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
  }).format(amount);
};

export const formatMoney = (money: { amount: number; currencyCode: string; precision: number }) => {
  if (!money || money.amount === undefined || money.currencyCode === undefined || money.precision === undefined) {
    return 'N/A';
  }

  return formatCurrency(money.amount / Math.pow(10, money.precision), money.currencyCode);
};

const suffixMap = {
  D: {
    singular: 'Tag',
    plural: 'Tage',
    pluraln: 'Tagen',
  },
  M: {
    singular: 'Monat',
    plural: 'Monate',
    pluraln: 'Monaten',
  },
  Y: {
    singular: 'Jahr',
    plural: 'Jahre',
    pluraln: 'Jahre',
  },
  S: {
    singular: 'Sekunde',
    plural: 'Sekunden',
    pluraln: 'Sekunden',
  },
  H: {
    singular: 'Stunde',
    plural: 'Stunden',
    pluraln: 'Stunden',
  },
  W: {
    singular: 'Woche',
    plural: 'Wochen',
    pluraln: 'Wochen',
  },
};

export const formatPeriod = (period: string, addN = false, multiplicator: number | undefined = undefined) => {
  if (period === undefined) {
    return '';
  }

  if (period === null) {
    return 'einmalig';
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[1] === undefined) {
    return period;
  }

  const number = parseInt(matches[1]) * (multiplicator || 1);
  const suffix = matches[2];

  let key = addN ? 'pluraln' : 'plural';
  if (number === 1) {
    key = 'singular';
  }

  // @ts-ignore
  const suffixString = suffixMap[suffix][key];

  return number + ' ' + suffixString;
};

export const extractPeriodNumber = (period: string, returnOnlyNumber = false) => {
  if (period === undefined) {
    if (returnOnlyNumber) return undefined;

    return '';
  }

  if (period === null) {
    if (returnOnlyNumber) return 0;
    return 'einmalig';
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[1] === undefined) {
    return period;
  }

  return parseInt(matches[1]);
};

export const extractPeriodUnit = (period: string): string => {
  if (period === undefined) {
    return '';
  }

  if (period === null) {
    return 'einmalig';
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[2] === undefined) {
    return period;
  }

  return matches[2];
};

export const extractPeriodUnitReadable = (period: string, type: 'singular' | 'plural' = 'singular') => {
  if (period === undefined) {
    return '';
  }

  if (period === null) {
    return 'einmalig';
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[2] === undefined) {
    return period;
  }

  const suffixString = suffixMap[matches[2]][type];

  return suffixString;
};

export const addPeriodToDate = (date: Date, period: string | null): Date => {
  if (period === undefined) {
    return date;
  }

  if (period === null) {
    return date;
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[1] === undefined) {
    return date;
  }

  const number = parseInt(matches[1]);
  const suffix = matches[2];

  switch (suffix) {
    case 'D':
      return moment(date).add(number, 'days').toDate();
    case 'M':
      return moment(date).add(number, 'months').toDate();
    case 'Y':
      return moment(date).add(number, 'years').toDate();
    case 'S':
      return moment(date).add(number, 'seconds').toDate();
    case 'H':
      return moment(date).add(number, 'hours').toDate();
    case 'W':
      return moment(date).add(number, 'weeks').toDate();
  }

  return date;
};

export const formatPeriodToSuffix = (period: string, addN = false, forcePlural = false) => {
  if (period === undefined) {
    return '';
  }

  if (period === null) {
    return 'einmalig';
  }

  const newPeriod = period.replace('P', '');
  const regex = /P(\d*)(M|D|Y|S|H|W).*/g;
  const matches = regex.exec('P' + newPeriod);

  if (matches === null || matches[1] === undefined) {
    return period;
  }

  const number = parseInt(matches[1]);
  const suffix = matches[2];

  let key = addN ? 'pluraln' : 'plural';
  if (number === 1) {
    key = 'singular';
  }

  if (forcePlural) {
    key = addN ? 'pluraln' : 'plural';
  }

  // @ts-ignore
  return suffixMap[suffix][key];
};

export const convertTimeToBeforeString = (t, time: string) => {
  const date = new Date(time);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffInMinutes = Math.floor(diff / 1000 / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  if (diffInYears > 0) {
    return t('components.TimelineStacked.time.years', { count: diffInYears });
  }

  if (diffInMonths > 0) {
    return t('components.TimelineStacked.time.months', { count: diffInMonths });
  }

  if (diffInDays > 0) {
    return t('components.TimelineStacked.time.days', { count: diffInDays });
  }

  if (diffInHours > 0) {
    return t('components.TimelineStacked.time.hours', { count: diffInHours });
  }

  if (diffInMinutes > 0) {
    return t('components.TimelineStacked.time.minutes', {
      count: diffInMinutes,
    });
  }

  return 'vor wenigen Sekunden';
};

export function parseDate(input: string, format: string) {
  format = format || 'yyyy-mm-dd'; // default format
  const parts = input.match(/(\d+)/g);
  let i = 0;
  const fmt = {};
  // extract date-part indexes from the format
  format.replace(/(yyyy|dd|mm)/g, function (part) {
    fmt[part] = i++;
  });

  return new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd]);
}
