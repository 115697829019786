<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext & {
    pipelineId: string
  }>
})

if (!props.context.pipelineId) {
  throw new Error('pipelineId is required')
}
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="`/api/v1/crm/pipelines/${context?.pipelineId}/stages`"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.name,
          value: data.id
        }
      }"
      :default-placeholder="'Deal-Pipeline-Stage auswählen'"
      :preload-options="true"
    />
  </div>
</template>
