<script setup lang="ts">
import { useFlag } from "~/plugins/flagsmith";

const customFieldsFlag = useFlag('custom_fields');
</script>

<template>
  <FormKit type="hidden" name="type" value="product" />

  <FormKit type="translationGroup" name="translations" :value="{ de: { locale: 'de' } }">
    <FormKit
      type="primeInputText"
      name="name"
      label="Name"
      validation="required"
      help="Der Name wird als Rechnungsposition und Addon-Name verwendet."
      placeholder="Service M"
    />
    <FormKit
      type="editor"
      :active-buttons="['bold', 'italic', 'strike', 'underline', 'bulletList', 'orderedList']"
      name="description"
      label="Beschreibung"
      help="Die Beschreibung wird als Rechnungsposition und Addon-Beschreibung verwendet."
      rows="5"
      placeholder="Features: A,B,C"
      nullable
      validation="length:0,10000"
      :validation-messages="{
        length: 'Die Beschreibung ist zu lang.',
      }"
    />
  </FormKit>

  <FormKit
    type="primeInputText"
    name="number"
    label="Produktnummer"
    validation="length:2,255"
    :nullable="true"
    placeholder="Produktnummer"
    help="Wähle eine Produktnummer, um einfacher nach Produkten zu suchen."
  />

  <FormKit
    type="primeInputText"
    name="internalName"
    label="Interner Name"
    validation="length:2,255"
    :nullable="true"
    placeholder="Interner Name"
    help="Der interne Name kann zur Differenzierung von gleichnamigen Produkten verwendet werden. Sofern angegeben, wird dieser in Listen und Auswahlfeldern verwendet."
  />

  <FormKit
    type="measurementDropdown"
    name="measurement"
    label="Abrechenbare Einheit"
    validation="required"
    :preselect-option="true"
    default-value="quantity"
    default-field="code"
    help="Die Einheit wird als Rechnungsposition und bei der Änderung von Einheiten im Checkout oder Self-Service verwendet."
  />

  <FormKit
    type="primeDropdown"
    name="invoiceVisibility"
    label="Sichtbarkeit auf der Rechnung"
    value="always"
    validation="required"
    :options="[
      { label: 'Immer', value: 'always' },
      { label: 'Nur wenn Menge > 0', value: 'only_if_charged' },
    ]"
    help="Produkte können in der Rechnung ausgeblendet werden, wenn keine Abrechnungsbeträge für den Abrechnungszeitraum vorhanden sind."
  />

  <FormKit
    type="taxGroupDropdown"
    name="taxGroup"
    label="Steuergruppe"
    validation="required"
    help="Dient zur Berechnung der Steuer für die Rechnungsposition."
    default-value="standard"
    default-field="type"
    :preselect-option="true"
  />

  <FormKit
    type="costCentreDropdown"
    name="costCentre"
    label="Kostenstelle"
    :filter="true"
    help="Kostenstellen dienen zur einfacheren internen Filterung und Zuordnung von Produkten in der Buchhaltung."
  />

  <FormKit v-if="customFieldsFlag" type="customFields" name="customFields" entity-type="product" />
  <FormKit v-else type="hidden" name="customFields" />
</template>

<style scoped lang="scss"></style>
