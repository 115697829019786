<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import { DynamicDialogInstance } from "primevue/dynamicdialogoptions";

const dialogRef: Ref<DynamicDialogInstance> = inject('dialogRef');

const onSubmit = (data) => {
  dialogRef.value.close({
    language: data.language,
  });
}
</script>

<template>
  <div class="min-w-[320px]">
    <p>
      Bitte wähle eine Sprache aus.
    </p>

    <FormKit type="form" :actions="false" @submit="onSubmit">
      <FormKit type="languageDropdown" name="language" />

      <FormKit type="primeSubmitButton" submit-label="Sprache hinzufügen" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
