import {defineNuxtRouteMiddleware, usePermissions} from '#imports';
import {useFlag} from "~/plugins/flagsmith";
import {AppPermissions} from "~/types/Permissions";

declare module '#app/../pages/runtime/composables' {
  interface PageMeta {
    canAnyOfPermissions?: AppPermissions[];
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const canAnyOfPermissions = to.meta.canAnyOfPermissions as string[] | undefined;
  if (!canAnyOfPermissions) {
    return;
  }

  // should handle permissions only on client side, because we do not have the permissions on server side
  if (process.server) return;

  if (!usePermissions().canAny(canAnyOfPermissions)) {
    return '/?permissionDenied=true';
  }
});
