import type { FormKitNode } from '@formkit/core';
import { email as emailValidation } from '@formkit/rules';

const emailArray = function (node: FormKitNode<string[]>): boolean {
  if (!Array.isArray(node.value)) {
    return false;
  }
  // Check each email in the array with FormKit's built-in validation
  return node.value.every((email) => {
    // Creating a temporary node object to use with the emailValidation function
    const tempNode: FormKitNode = {
      ...node, // Spreading the original node to inherit properties
      value: email, // Setting the value to the current email string
    };
    return emailValidation(tempNode);
  });
};

// Optional: Override default rule behaviors if needed
emailArray.blocking = true;
emailArray.skipEmpty = true;

export default emailArray;
