<script setup lang="ts">
const props = defineProps({
  amount: {
    type: Number,
    required: true
  },
  currencyCode: {
    type: String,
    required: true
  },
  as: {
    type: String,
    required: false,
    default: 'span'
  },
  negative: {
    type: Boolean,
    required: false,
    default: false
  },
  colored: {
    type: Boolean,
    required: false,
    default: false
  },
  revertColor: {
    type: Boolean,
    required: false,
    default: false
  },
})

const formattedMoney = computed(() => {
  if (!props.currencyCode) {
    return ''
  }

  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: props.currencyCode,
  }).format(props.negative ? props.amount * -1 : props.amount)
})

const colorClass = computed(() => {
  if (props.colored) {
    if (props.revertColor) {
      return props.amount < 0 ? 'text-green-500' : 'text-red-500'
    }
    return props.amount < 0 ? 'text-red-500' : 'text-green-500'
  }
})
</script>

<template>
  <component :is="as" :class="colorClass" class="whitespace-nowrap ph-no-capture">{{ formattedMoney }}</component>
</template>

<style scoped lang="scss">

</style>
