<script setup lang="ts">
import {PropType} from "vue";

defineProps({
  isOptionValid: {
    type: Boolean,
    default: true,
  },
  removeButton: {
    type: Boolean,
    default: true
  },
  editButton: {
    type: Boolean,
    default: true
  },
  draggable: {
    type: Boolean,
    default: false
  },
  invalidMessage: {
    type: String as PropType<String | null>,
    default: null
  },
  highlight: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits({
  "remove": () => true,
  "edit": () => true,
});
</script>

<template>
  <div class="app-option-item p-panel p-component p-panel-toggleable" :class="{
    'p-panel-highlight': highlight,
    'p-panel-invalid': !isOptionValid,
  }">
    <div class="p-panel-header">
      <slot name="preHeader" />

      <div>
        <button class="mr-3 hover:cursor-move hover:text-black app-option-item--drag-button" @click.prevent v-if="draggable"><i class="pi-arrows-v pi"></i></button>
        <span class="p-panel-title">
              <slot name="title" />
        </span>
      </div>

      <div class="p-panel-icons flex gap-1">
        <button v-if="removeButton" class="p-panel-header-icon p-panel-toggler p-link hover:text-red-500 hover:bg-red-100" @click.prevent="emit('remove')"><i class="pi pi-trash" /></button>
        <button v-if="editButton" class="p-panel-header-icon p-panel-toggler p-link" @click.prevent="emit('edit')"><i class="pi pi-pencil" /></button>
      </div>
    </div>

    <div v-if="!isOptionValid" class="p-panel-invalid-message">
      <i class="pi pi-exclamation-triangle pr-1" /> {{ invalidMessage }}
    </div>
  </div>
</template>

<style lang="scss">
.app-option-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  .p-panel-header-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    position: relative;
  }

  .p-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.p-panel-invalid {
    border-color: var(--error-color);
    overflow: auto !important;
  }

  .p-panel-invalid-message {
    padding: 0.5rem 1rem;
    color: var(--error-color);
  }

  &.p-panel-highlight {
    border-color: var(--gray-600);
    overflow: auto;
  }
}
</style>
