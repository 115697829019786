import cubejs, { Query } from '@cubejs-client/core';
import { Ref } from 'vue';
import { ref } from '#imports';

class CubeJsApi {
  private apiUrl: string;
  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  load(query: Query) {
    return cubejs(
      async (): Promise<string> => {
        const data = $fetch('/api/search/token', {
          method: 'GET',
        });

        if (!data?.data.token) {
          throw new Error('No token found');
        }

        return data?.data.token;
      },
      {
        apiUrl: this.apiUrl + '/cubejs-api/v1',
      }
    ).load(query);
  }
}

export const $cubeJsApiInstance: Ref<CubeJsApi | null> = ref(null);

export default defineNuxtPlugin((nuxtApp) => {
  const cubeJsInstance = new CubeJsApi('https://sandbox.fynn.eu/.well-known/cubejs');

  nuxtApp.provide('cubeJsApiInstance', cubeJsInstance);
  $cubeJsApiInstance.value = cubeJsInstance;
});
