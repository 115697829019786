// formkit.config.ts
import { primeInputs } from '@sfxcode/formkit-primevue';
import { de, en } from '@formkit/i18n';
import { defineFormKitConfig } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import { FormKitNode } from '@formkit/core';
import { appInputs } from '~/formkit/inputs';
import { rules } from '~/formkit/rules';
import { createMultiStepPlugin } from '@formkit/addons'


const isCheckboxAndRadioMultiple = (node: FormKitNode) => (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;

function addAsteriskPlugin(node: FormKitNode) {
  node.on('created', () => {
    const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');
    const isRequiredNullable = node.props.parsedRules.some((rule) => rule.name === 'requiredNullable');
    if (!isRequired && !isRequiredNullable) return;

    const isMultiOption = isCheckboxAndRadioMultiple(node);

    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an
    // invalid cached schema.
    // @ts-ignore
    node.props.definition.schemaMemoKey = `required_${isMultiOption ? 'multi_' : ''}${node.props.definition.schemaMemoKey}`;

    // @ts-ignore
    const schemaFn = node.props.definition.schema;
    // @ts-ignore
    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isRequired || isRequiredNullable) {
        if (isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label', {
              $el: 'span',
              attrs: {
                class: 'text-red-500 pl-1'
              },
              children: '*',
            }],
          };
        } else {
          sectionsSchema.label = {
            children: ['$label', {
              $el: 'span',
              attrs: {
                class: 'text-red-500  pl-1'
              },
              children: '*',
            }],
          };
        }
      }
      return schemaFn(sectionsSchema);
    };
  });
}

function addModalOnPageChange(node: FormKitNode) {
  if (node.props.type !== 'form') return false;

  // when form is not submitted, show before page change a modal

}

function resetInputOnOtherInputChanges(node: FormKitNode) {
  if (node.props.type !== 'form') return false;

  const onChild = (child: FormKitNode) => {
    const inputNames = child.props?.attrs?.resetOnInput || [];
    if (inputNames.length === 0) return true;

    const reset = () => {
      // todo: improve performance! For large forms this could take a while
      node.walk((resetChild) => {
        if (inputNames.includes(resetChild.name)) {
          resetChild.reset();
        }
      });
    };

    child.on('input', reset);
  };

  node.on('child', ({ payload: child }) => {
    onChild(child);
  });
}

function scrollToErrors(node: FormKitNode) {
  if (node.props.type === 'form') {
    function scrollTo(node: FormKitNode) {
      const el = document.getElementById(node.props.id);
      if (el) {
        el.scrollIntoView();
      }
    }

    function scrollToErrors() {
      node.walk((child) => {
        // Check if this child has errors
        if (child.ledger.value('blocking') || child.ledger.value('errors')) {
          // We found an input with validation errors
          scrollTo(child);
          // Stop searching
          return false;
        }
      }, true);
    }

    const onSubmitInvalid = node.props.onSubmitInvalid;
    node.props.onSubmitInvalid = () => {
      if (onSubmitInvalid) onSubmitInvalid(node);
      scrollToErrors();
    };
    node.on('unsettled:errors', scrollToErrors);
  }
  return false;
}

// nullable plugin: on form submit, if a field is empty, it will be set to null, if the field has a "nullable" attribute
function nullablePlugin(node: FormKitNode) {
  // if is form skip
  if (node.props.type === 'form') return false;

  const isNullable =
    node.props?.attrs?.nullable === true ||
    node.context?.attrs?.nullable === true ||
    node.props?.nullable === true ||
    node.context?.props?.nullable === true;

  if (!isNullable) return false;

  node.on('created', () => {
    node.hook.input((value, next) => {
      if (typeof value === 'string' && value.trim() === '') {
        return next(null);
      }

      return next(value);
    });

    if (typeof node?._value === 'string' && node._value.trim() === '') {
      node.input(null);
    }
  });
}

export default defineFormKitConfig(() => {
  // here we can access `useRuntimeConfig` because
  // our function will be called by Nuxt.
  const config = useRuntimeConfig();

  // and we can use the variables to import secrets.
  //
  // ⚠️ this is just an example — if you want to use FormKit Pro
  // you will need to install the @formkit/pro dependency.
  const pro = createProPlugin(config.public.formkitProKey, inputs);

  return {
    plugins: [pro, addAsteriskPlugin, scrollToErrors, resetInputOnOtherInputChanges, nullablePlugin, addModalOnPageChange, createMultiStepPlugin()],

    locales: { de, en },
    locale: 'de',
    inputs: {
      ...primeInputs,
      ...appInputs,
    },
    rules,
  };
});
