<script setup lang="ts">
import ProductName from "~/components/Product/ProductName.vue";
import type {PropType} from "vue";
import type {ProductSetOption} from "~/types/productSet";
import AppOptionItem from "~/components/AppOptionItem.vue";
import AppOptionItemFormKitValidation from "~/components/AppOptionItemFormKitValidation.vue";

const props = defineProps({
  draggable: {
    type: Boolean,
    default: false
  },
  option: {
    type: Object as PropType<ProductSetOption>,
    required: true
  },
  totalPricePlanPoints: {
    type: Number,
    required: true
  }
})

const emit = defineEmits({
  "remove": () => true,
  "edit": () => true,
});

const isOptionValid = computed(() => {
  return !!props.option.product &&
      (
          (props.option?.allignBillingInterval === true && props.option?.pricePlans?.length === props.totalPricePlanPoints)
          || (props.option?.allignBillingInterval === false && props.option?.pricePlans?.length > 0)
      )
})

const invalidMessage = computed(() => {
  if (!props.option.product && props.option?.pricePlans?.length === 0) {
    return 'Bitte wähle ein Produkt aus und füge alle notwendigen Preise hinzu.'
  }

  if (props.option?.pricePlans?.length === 0) {
    return 'Bitte füge alle notwendigen Preise hinzu.'
  }

  return null
})

const optionValidFieldName = computed(() => {
  return `productSetOptions[${props.option.id}][valid]`
})

const hasAllPricePoints = computed(() => {
  if (props.option?.allignBillingInterval === false) {
    return props.option.pricePlans.length > 0
  }

  return props.option.pricePlans.length === props.totalPricePlanPoints
})

const totalPricePlanPointsCount = computed(() => {
  if (props.option?.allignBillingInterval === false) {
    return 1
  }

  return props.totalPricePlanPoints
})

const isFormIncomplete = ref(false)
</script>

<template>
  <AppOptionItem
          :draggable="draggable"
          :is-option-valid="!(!isOptionValid && isFormIncomplete)"
          :invalid-message="invalidMessage"
          :edit-button="true"
          :remove-button="true"
          :highlight="option.preselected"
          @edit="emit('edit')"
          @remove="emit('remove')"
  >
      <template #preHeader>
          <AppOptionItemFormKitValidation
              :key="isOptionValid"
              :field-name="optionValidFieldName"
              :is-option-valid="isOptionValid"
              :invalid-message="invalidMessage"
              @incomplete="isFormIncomplete = $event"
          />
      </template>

      <template #title>
            <div>
              <span v-if="option.product">
                <span v-if="typeof option.product === 'string'"><ProductName :product-id="option.product" /></span>
                <span v-else>{{ option.product?.name }}</span>
              </span>
              <span v-else class="italic font-medium text-gray-500">Neue Option</span>

              <span class="text-sm pr-3 pl-2" :class="{
                'text-gray-400': hasAllPricePoints,
                'text-red-600': !hasAllPricePoints
              }">
                {{ option.pricePlans.length }} von {{ totalPricePlanPointsCount }} Preis<span v-if="totalPricePlanPointsCount !== 1">en</span> definiert
              </span>
            </div>
      </template>
  </AppOptionItem>
</template>

<style>

</style>
