import {Ref} from "@vue/reactivity";

export const priceTypeRecurringOptions = (t: any) => {
  return [
    {value: 'flat_fee', label: t('entities.PricePlan.type.options.flat_fee.label')},
    {value: 'per_unit', label: t('entities.PricePlan.type.options.per_unit.label')},
    {value: 'tiered', label: t('entities.PricePlan.type.options.tiered.label')},
    {value: 'volume', label: t('entities.PricePlan.type.options.volume.label')},
    {value: 'stair_step', label: t('entities.PricePlan.type.options.stair_step.label')},
    {value: 'percentage', label: t('entities.PricePlan.type.options.percentage.label')},
  ]
}

export const priceTypeNonRecurringOptions = (t: any) => {
  return [
    {value: 'flat_fee', label: t('entities.PricePlan.type.options.flat_fee.label')},
    {value: 'per_unit', label: t('entities.PricePlan.type.options.per_unit.label')},
  ]
}

export const pricePlanFormFields = (t: any) => {
  return {
    priceTypeField() {
      return {
        $formkit: 'primeDropdown',
        name: 'type',
        id: 'priceType',
        label: 'Preistyp',
        validation: 'required',
        options: {
          if: '$value.billingInterval',
          then: priceTypeRecurringOptions(t),
          else: priceTypeNonRecurringOptions(t),
        },
        value: 'flat_fee',
        help: 'Der Preistyp bestimmt, wie der Preis berechnet wird.',
      }
    },

    flagFields() {
      return {
        $el: 'div',
        attrs: {
          class: 'md:grid md:grid-cols-1 gap-3 mt-4 price-plan-form__billing'
        },
        children: [
          {
            $formkit: 'primeInputSwitch',
            name: 'applyTrial',
            label: 'Testphase anwendbar',
            value: true,
            help: 'Wenn aktiviert, wird der Preis erst nach der Testphase berechnet. Andernfalls ist der Preis sofort fällig.',
          },
          //payInAdvance
          {
            $formkit: 'primeInputSwitch',
            name: 'payInAdvance',
            label: 'Vorauszahlung',
            value: true,
            help: 'Wenn aktiviert, wird der Preis im Voraus berechnet. Andernfalls ist der Preis nach dem Abrechnungsinterval fällig.',
          },
          {
            $formkit: 'primeInputSwitch',
            name: 'proRata',
            label: 'Anteilige Berechnung',
            value: {
              if: '$value.billingInterval',
              then: true,
              else: false,
            },
            disabled: {
              if: '$value.billingInterval',
              then: false,
              else: true,
            },
            help: 'Der Preis wird anteilig berechnet (proratiert), wenn nicht der vollständige Abrechnungsinterval abgerechnet wird.',
          },
          {
            if: '!$value.billingInterval',
            $el: 'span',
            attrs: {
              class: 'text-sm text-gray-500 -mt-2'
            },
            children: [
              'Die Option "Anteilige Berechnung" ist nur für wiederkehrende Preise verfügbar.'
            ]
          }
        ]
      };
    },

    freeUnitsField() {
      return {
        if: '$value.billingInterval',
        $formkit: 'primeInputNumber',
        name: 'freeUnits',
        label: 'Freie Einheiten',
        minFractionDigits: 0,
        maxFractionDigits: 10,
        value: null,
        help: 'Die Anzahl der inklusiven Einheiten, die der Kunde erhält. Diese Einheiten werden nicht berechnet.',
      };
    },

    priceField(currencyCode: Ref<string>) {
      return {
        $el: 'div',
        attrs: {
          class: 'md:grid md:grid-cols-6 gap-3 price-plan-form__price'
        },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'md:col-span-4 grid grid-cols-1 gap-3'
            },
            children: [
              {
                if: '($value.type === "per_unit" || $value.type === "flat_fee") && $value.currencyCode',
                $formkit: 'group',
                name: 'price',
                label: 'Preis',
                key: 'unit_fee',
                validation: 'required',
                children: [
                  {
                    if: '$value.currencyCode',
                    $formkit: 'primeInputNumber',
                    name: 'amount',
                    label: {
                      if: '$value.type === "flat_fee"',
                      then: 'Pauschaler wiederkehrender Betrag',
                      else: 'Betrag pro Einheit'
                    },
                    mode: 'currency',
                    currency: '$value.currencyCode',
                    minFractionDigits: 2,
                    maxFractionDigits: 10,
                    value: 0,
                    validation: 'required'
                  },
                  {
                    $el: 'div',
                    children: ['Währung:', '$value.currencyCode']
                  }
                ]
              },
              {
                if: '($value.type === "volume" || $value.type === "tiered" || $value.type === "stair_step") && $value.currencyCode',
                $formkit: 'group',
                name: 'price',
                label: 'Preis',
                key: 'tiered',
                validation: 'required',
                children: [
                  {
                    $formkit: 'pricePlanTier',
                    if: '$value.currencyCode',
                    currencyCode: '$value.currencyCode',
                    name: 'items',
                    validation: 'min:1',
                    value: [{
                      from: 0,
                      amount: 0,
                      flatAmount: 0,
                    }]
                  },
                ]
              },
              // percentage
              {
                if: '$value.type === "percentage" && $value.currencyCode',
                $formkit: 'group',
                name: 'price',
                label: 'Preis',
                key: 'percentage',
                children: [
                  {
                    if: '$value.currencyCode',
                    $formkit: 'primeInputNumber',
                    name: 'amount',
                    label: 'Betrag pro Einheit',
                    mode: 'currency',
                    currency: '$value.currencyCode',
                    minFractionDigits: 2,
                    maxFractionDigits: 10,
                    value: 0,
                    validation: 'required',
                    help: 'Der Betrag, der pro Einheit berechnet wird. Dieser Betrag wird mit der Anzahl der Einheiten (- prozentualer Anteil) multipliziert.',
                  },
                  {
                    $formkit: 'primeInputNumber',
                    name: 'percentage',
                    label: 'Prozentualer Anteil',
                    suffix: ' %',
                    value: 0,
                    min: 0,
                    max: 100,
                    minFractionDigits: 0,
                    maxFractionDigits: 2,
                    validation: 'required',
                    help: 'Der prozentuale Anteil der Einheiten, die berechnet werden. Bei Nutzung von 100 Einheiten und einem prozentualen Anteil von 30% werden 30 Einheiten berechnet.',
                  },
                  {
                    $formkit: 'primeInputNumber',
                    name: 'freeUnitsPerEvent',
                    label: 'Freie Einheiten pro Event',
                    minFractionDigits: 0,
                    maxFractionDigits: 10,
                    suffix: ' Einheiten',
                    value: 0,
                    help: 'Die Anzahl der inklusiven Einheiten pro gemeldeten verbrauchten Einheiten.',
                  },
                  {
                    $formkit: 'primeInputNumber',
                    name: 'freeUnitsPerTotalAggregated',
                    label: 'Freie Einheiten pro Gesamtsumme',
                    value: 0,
                    help: 'Die Anzahl der inklusiven Einheiten je Abrechnungsinterval.',
                  },
                ]
              }
            ]
          },
          {
            $el: 'div',
            attrs: {
              class: 'md:col-span-2'
            },
            children: [
              {
                $cmp: 'PriceExampleCalculator',
                if: '$value.type && $value.price && $value.currencyCode',
                props: {
                  type: '$value.type',
                  price: '$value.price',
                  currencyCode: '$value.currencyCode',
                  freeUnits: '$value.freeUnits || 0',
                  applyTrial: '$value.applyTrial',
                  //billingInterval: '$value.billingInterval',
                }
              }
            ]
          }
        ]
      };
    }
  }
}
