import * as Sentry from "@sentry/vue";
import type {ExclusiveEventHintOrCaptureContext} from "@sentry/core/types/utils/prepareEvent";

// api exception class which can be used to handle api exceptions in ErrorBoundary
export class ApiExceptionError extends Error {
  constructor(details: {
    title: string;
    message: string;
    actionButton?: {
      label: string;
      action: () => void;
    }
  }) {
    super();
    this.name = 'ApiExceptionError';
    this.title = details.title;
    this.message = details.message;
    this.actionButton = details?.actionButton || null;
  }

  title: string;
  message: string;
  actionButton?: {
    label: string;
    action: () => void;
  }
}

export default function useErrorHelper() {
  const fatalError = useFatalError();

  const handleApiException = (error: {
    response: {
      status: number;
      _data: {
        type: string;
        [key: string]: any;
      };
    };
  }, handlers: Object<{
    [key: string]: (apiExceptionMessage: any) => void | ApiExceptionError;
  }>) => {
    const mapHandler = new Map<string, (apiExceptionMessage: any) => void | ApiExceptionError>();
    Object.keys(handlers).forEach((key) => {
      mapHandler.set(key, handlers[key]);
    });

    if (error.response.status === 502) {
      fatalError.show();
    }

    else if (error.response.status === 401) {
      if (import.meta.client || import.meta.browser) {
        window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname);
      }
    }

    else if (error.response.status === 403) {
      navigateTo('/forbidden');
    }

    else if (error.response._data.type) {
      const handler = mapHandler.get(error.response._data.type);
      if (handler) {
        const result = handler(error.response._data.type);
        if (result instanceof ApiExceptionError) {
          throw result;
        }
      } else {
        throw error;
      }
    }

    else {
      throw error;
    }
  }

  const captureException = (exception: any, hint?: ExclusiveEventHintOrCaptureContext) => {
    Sentry.captureException(exception, hint);
  }

  return {
    handleApiException,
    captureException,
  };
}
