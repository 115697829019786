<script setup lang="ts">
import { PropType } from 'vue';
import type { FormKitFrameworkContext } from '@formkit/core';
import { useDialog } from 'primevue/usedialog';
import ApiDropdown from '~/formkit/inputs/ApiDropdown.vue';
import { AppPermissions } from '~/types/Permissions';
import AppDropdownCreateButton from '~/components/AppDropdownCreateButton.vue';
import CreateProductForm from '~/components/Product/CreateProductForm.vue';
import { useApiDropdown } from '~/store/api-dropdown';
import { useProduct } from '~/store/product';

export type ProductDropdownProps = {
  productType?: string;
  dropdownType?: string;
  allowCreate?: boolean;
  preloadOptions?: boolean;
};

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext & ProductDropdownProps>,
});

const dialog = useDialog();
const type = props.context?.productType || props.context?.attrs?.productType || undefined;
const dropdownType = props.context?.dropdownType || props.context?.attrs?.dropdownType || 'single';
const allowCreate = props.context?.allowCreate || props.context?.attrs?.allowCreate || false;
const preloadOptions = props.context?.preloadOptions || props.context?.attrs?.preloadOptions || false;
const createProductDefaultData = props.context?.createProductDefaultData || {};

props.context.attrs.filter = true;

const apiEndpoint = computed(() => {
  if (type) {
    return '/api/v1/catalogue/products?type=' + type;
  }

  return '/api/v1/catalogue/products';
});

const headerComponentProps = {
  title: 'Neues Produkt hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(CreateProductForm, {
      props: {
        header: 'Neues Produkt hinzufügen',
        modal: true,
      },
      data: {
        type,
        defaultValues: createProductDefaultData,
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.onCreate?.(data.data);
          await refresh();
          await useApiDropdown().addItem(data.data);
          await useProduct().addProduct(data.data);

          if (type === 'single') {
            setInputValue(data.data.id);
          } else {
            setInputValue([data.data.id]);
          }
        }
      },
    });
  },
};
</script>

<template>
  <div>
    <ApiDropdown
      :type="dropdownType"
      :context="context"
      :api-endpoint="apiEndpoint"
      :api-transform-function="
        (data: any, { lang }) => {
          return {
            ...data,
            label: data.internalName ?? data?.name,
            value: data.id,
          };
        }
      "
      :preload-options="preloadOptions"
      :default-placeholder="'Produkt auswählen'"
      :header-component-props="allowCreate ? headerComponentProps : undefined"
      :header-component="allowCreate ? AppDropdownCreateButton : undefined"
      :header-component-permissions="[AppPermissions.ProductWrite]"
    />
  </div>
</template>
