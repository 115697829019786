<script setup lang='ts'>
import {PropType, ref} from "vue";
import {FormKitFrameworkContext} from "@formkit/core";
import ProductSetList from "~/components/Plan/ProductSetList.vue";
import {PricePlanPoint} from "~/types/productSet";

export type PlanProductSetListProps = {
  pricePlanPoints: PricePlanPoint[]
}

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext & PlanProductSetListProps>
})

const context: FormKitFrameworkContext & PlanProductSetListProps = props.context

const attrs = computed(() => props.context?.attrs)

const styleClass = computed(() => (props.context?.state.validationVisible && !props.context?.state.valid) ? `${attrs.value?.class} p-invalid` : attrs.value?.class)

function handleChange(e: any) {
  console.log('handleChange', e)
  props.context?.node.input(e)
}
</script>

<template>
  <div>
    <div :id="context?.id">
      <ProductSetList
          :price-plan-points="context.pricePlanPoints"
          :product-sets="context._value"
          @update:product-sets="handleChange"
      />
    </div>
  </div>
</template>
