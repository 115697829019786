<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import ProductFormFields from '~/components/Product/ProductFormFields.vue';

const toast = useToast();
const emit = defineEmits(['onSuccess']);

const dialogRef = inject('dialogRef');

const { submitHandler, nodeHandler } = useForm();

const onSubmit = async (data: any, node: any) => {
  await submitHandler(
    {
      route: '/api/v1/catalogue/products',
      method: 'POST',
      successHandler(response) {
        toast.add({
          severity: 'success',
          summary: 'Produkt erstellt',
          detail: `Das Produkt "${response.data.name}" wurde erfolgreich erstellt.`,
          life: 3500,
        });

        emit('onSuccess', response);

        // @ts-ignore
        dialogRef?.value.close(response.data);
      },
      customErrorHandler() {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Das Produkt konnte nicht erstellt werden.`,
          life: 3500,
        });
      },
    },
    {
      ...(dialogRef?.value?.data?.defaultValues ?? {}),
      ...data,
    },
    node
  );
};
</script>

<template>
  <div class="max-w-[500px]">
    <FormKit type="form" :actions="false" @submit="onSubmit" @node="nodeHandler">
      <ProductFormFields />

      <FormKit type="primeSubmitButton" submit-label="Produkt anlegen" button-icon="pi pi-plus" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss"></style>
