import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/usr/src/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/app/.nuxt/sentry-client-config.mjs";
import formkitPlugin_pZqjah0RUG from "/usr/src/app/.nuxt/formkitPlugin.mjs";
import primevue_7rYYRZQLyx from "/usr/src/app/plugins/primevue.ts";
import clipboard_pCdazDdG5Z from "/usr/src/app/plugins/clipboard.ts";
import cubejs_etkeOJDFTn from "/usr/src/app/plugins/cubejs.ts";
import i18n_VfGcjrvSkj from "/usr/src/app/plugins/i18n.ts";
import codemirror_client_HUHw5DbP6j from "/usr/src/app/plugins/codemirror.client.ts";
import permissions_MHRAHWKe7t from "/usr/src/app/plugins/permissions.ts";
import quill_client_yJPGM7qEq9 from "/usr/src/app/plugins/quill.client.ts";
import errorHandler_x1Y4n5NP4w from "/usr/src/app/plugins/errorHandler.ts";
import flagsmith_FvLfn3q8DA from "/usr/src/app/plugins/flagsmith.ts";
import piniaPersistedState_client_uM6K1KAp2N from "/usr/src/app/plugins/piniaPersistedState.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  formkitPlugin_pZqjah0RUG,
  primevue_7rYYRZQLyx,
  clipboard_pCdazDdG5Z,
  cubejs_etkeOJDFTn,
  i18n_VfGcjrvSkj,
  codemirror_client_HUHw5DbP6j,
  permissions_MHRAHWKe7t,
  quill_client_yJPGM7qEq9,
  errorHandler_x1Y4n5NP4w,
  flagsmith_FvLfn3q8DA,
  piniaPersistedState_client_uM6K1KAp2N
]