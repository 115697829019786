import isJwtTokenExpired, { decode } from 'jwt-check-expiry';
import { useAuthApi } from '~/composables/useAuthApi';
import { useUser } from '~/store/user';

export const useAuthClient = () => {
  const setTokens = (token: string, refreshToken?: string) => {
    const { payload } = decode(token);

    useCookie('auth:token', {
      expires: new Date(payload.exp * 1000),
      httpOnly: true,
    }).value = token;

    if (!refreshToken) {
      return;
    }

    useCookie('auth:refresh_token', {
      // expires in 30 days
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    }).value = refreshToken;
  };

  const cleanupCookies = () => {
    useCookie('auth:token', { expires: new Date(0) }).value = '';
    useCookie('auth:refresh_token', { expires: new Date(0) }).value = '';
  };

  return {
    login: async (username: string, password: string, rememberMe?: boolean, loginOnRegister?: boolean) => {
      try {
        const response = await useAuthApi().login(username, password);

        setTokens(response.token, rememberMe === false ? undefined: response.refreshToken);

        // wait 500 ms so the cookie is set before the user is loaded
        await new Promise((resolve) => setTimeout(resolve, 300));

        if (!loginOnRegister) {
          await useUser().loadUser();
        }
      } catch (e) {
        throw new Error('Invalid credentials');
      }
    },
    loginSocial: async (provider: 'google'|'microsoft', accessToken: string) => {
      try {
        const result = await $fetch('/api/v1/auth/social-login', {
          method: 'POST',
          body: {
            provider,
            accessToken
          }
        });

        setTokens(result.token);
        await useUser().loadUser();
      } catch (e) {
        throw new Error('Invalid token');
      }
    },
    cleanupCookies: () => {
      useCookie('auth:token', { expires: new Date(0) }).value = '';
      useCookie('auth:refresh_token', { expires: new Date(0) }).value = '';
    },
    logout: () => {
      useUser().clearUser();
      cleanupCookies();

      window.location.href = '/';
    },
    isAuthed: () => {
      if (process.server) {
        throw new Error('isAuthed() can only be used on the client');
      }

      const token = useCookie('auth:token').value;
      if (!token) {
        return false;
      }

      if (isJwtTokenExpired(token) && useCookie('auth:refresh_token')) {
        return true;
      }

      return !isJwtTokenExpired(token);
    },
    isRefreshable: () => {
      if (process.server) {
        throw new Error('isRefreshable() can only be used on the client');
      }

      return !!useCookie('auth:refresh_token').value;
    },
    getToken: () => {
      if (process.server) {
        throw new Error('getToken() can only be used on the client');
      }

      return useCookie('auth:token').value;
    },
    refreshTokens: async () => {
      if (process.server) {
        throw new Error('refreshTokens() can only be used on the client');
      }

      const refreshToken = useCookie('auth:refresh_token').value;
      const authToken = useCookie('auth:token').value;

      // check if token expires in 1 minute
      if (refreshToken) {
        if (authToken) {
          const { payload } = decode(authToken);
          if (payload.exp - Date.now() / 1000 > 60) {
            return;
          }
        }

        const host = window.location.host;
        const scheme = window.location.protocol;
        const origin = `${scheme}//${host}`;

        const { token: newToken, refreshToken: newRefreshToken } = await useAuthApi().refresh(origin + '/api/v1', refreshToken);
        const { payload } = decode(newToken);

        useCookie('auth:token', {
          expires: new Date(payload.exp * 1000),
        }).value = newToken;

        useCookie('auth:refresh_token', {
          // expires in 30 days
          expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        }).value = newRefreshToken;

        await useUser().loadUser();
      }
    },
  };
};
