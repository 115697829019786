import { defineStore } from 'pinia';

export const useApiDropdown = defineStore('api-dropdown', {
  state: () => ({
    foundItems: [] as any,
  }),
  actions: {
    async addItem(item) {
      if (!item || (!item?.id && !item?.value)) {
        return;
      }

      // check if id exists, otherwise if value exists
      const indexKey = item.id ? 'id' : (item.value ? 'value' : null);
      if (!indexKey) {
        return;
      }

      const index = this.foundItems.findIndex((c) => c[indexKey] === item[indexKey]);
      if (index === -1) {
        this.foundItems.push({
          ...item,
          id: item.id || item.value,
        });
      } else {
        this.foundItems[index] = {
          ...item,
          id: item.id || item.value,
        };
      }
    },
  },
  getters: {
    getById: (state) => (id) => {
      return state.foundItems.find((c) => c.id === id) || null;
    },
  },
});
