import { FormKitNode } from '@formkit/core';
import currencies from 'currencies.json';

/**
 * A contrived validation rule that checks if the value is a valid currency code.
 */
const currencyCode = function (node: FormKitNode) {
  const foundIndex = currencies.currencies.findIndex((currency) => currency.code === node.value);

  return foundIndex !== -1;
};

currencyCode.blocking = true;
currencyCode.skipEmpty = false;
currencyCode.debounce = 20; // milliseconds
currencyCode.force = true;

export default currencyCode;
