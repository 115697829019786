<script setup lang="ts">
const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
})

const intervalUnit = computed(() => {
  if (props.value === 'new') {
    return 'N'
  }
  return props.value?.slice(-1) ?? null
})

const intervalAmount = computed(() => {
  if (props.value === 'new') {
    return 1
  }

  return parseInt(props.value?.slice(0, -1) ?? '0')
})

const intervalUnitLabels = {
  N: {
    1: 'Neuen Preis erstellen',
  },
  D: {
    1: 'täglich',
    0: '{0} tägig',
  },
  W: {
    1: 'wöchentlich',
    0: '{0} wöchig',
  },
  M: {
    1: 'monatlich',
    3: 'vierteljährlich',
    6: 'halbjährlich',
    12: 'jährlich',
    0: '{0} monatig',
  },
  Y: {
    1: 'jährlich',
    0: '{0} jährig',
  },
  H: {
    1: 'stündlich',
    0: '{0} stündig',
  },
  I: {
    1: 'minütlich',
    0: '{0} minütig',
  },
  S: {
    1: 'sekündlich',
    0: '{0} sekündig',
  }
}

const label = computed(() => {
  if (props.value === undefined) {
    return '-'
  }

  if (props.value === null) {
    return 'einmalig'
  }
  const unit = intervalUnit.value
  const amount = intervalAmount.value

  let label = intervalUnitLabels[unit][amount] ?? null;
  if (label === null) {
    label = intervalUnitLabels[unit][0].replace('{0}', amount.toString())
  }

  if (props.uppercase) {
    label = label.charAt(0).toUpperCase() + label.slice(1)
  }

  return label
})
</script>

<template>
  <span>{{ label }}</span>
</template>
