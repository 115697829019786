<script setup lang="ts">
import { Ref } from 'vue';
import { FormKitNode, FormKitSchemaDefinition } from '@formkit/core';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Panel from 'primevue/panel';
import { useToast } from 'primevue/usetoast';
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import {
  contactPersonFieldSchema,
  customerAddressBaseSchema,
  customerBaseDataSchema,
  customerContactDataFieldSetSchema,
  customerGenericSchema,
  customerGroupFieldSchema,
  customerVatIdSchema,
  taxExemptDropdownSchema,
} from '~/formkit/customer';
import PassThroughFieldset from '~/components/FormKit/PassThroughFieldset.vue';
import { useFlag } from '~/plugins/flagsmith';
import { usePermissions } from '~/composables/usePermissions';
import { AppPermissions } from '~/types/Permissions';
import useForm from '~/composables/useForm';

const toast = useToast();
const emit = defineEmits(['onSuccess']);

const dialogRef: Ref<DynamicDialogInstance> = inject('dialogRef');

const customerType: 'customer' | 'partner' = dialogRef?.value?.data?.type || 'customer';

const accountingBasicFlag = useFlag('accounting.basic');
const customerGroupFlag = useFlag('customer.groups');
const contactPersonFlag = useFlag('customer.contact-person');
const customFieldsFlag = useFlag('custom_fields');

const { nodeHandler, submitHandler } = useForm();

const data = ref({});

const library = markRaw({
  Accordion,
  AccordionTab,
  Card: Panel,
  Fieldset: PassThroughFieldset,
});

const addressFieldset = {
  $cmp: 'Fieldset',
  props: {
    legend: 'Empfängeradresse',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    ...customerAddressBaseSchema,
    // customerCountryCodeSchema,
  ],
};

const contactDataFieldset = {
  $cmp: 'Fieldset',
  props: {
    legend: 'Kontaktdaten',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    {
      $formkit: 'primeInputMask',
      name: 'phone',
      mask: '+99 999 99999999',
      label: 'Telefon',
      placeholder: '+49 123 456789',
    },
  ],
};

const deferredInvoiceDataFieldset = {
  $cmp: 'Fieldset',
  props: {
    legend: 'Abweichende Rechnungsdaten',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    {
      $formkit: 'primeInputText',
      name: 'invoiceEmail',
      label: 'Abweichende Rechnungs-E-Mail',
      validation: 'email',
    },
    {
      $cmp: 'Fieldset',
      props: {
        legend: 'Abweichende Rechnungsadresse',
      },
      children: ['Diese kann erst nach dem Erstellen des Datensatz gesetzt werden.'],
    },
  ],
};

const datevFieldset = {
  $cmp: 'Fieldset',
  props: {
    legend: 'DATEV',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    {
      $el: 'div',
      attrs: {
        class: {
          // if: '$value.generateCustomerNumber',
          if: '$value.generateDatevId === false',
          then: 'md:grid md:grid-cols-2 md:gap-3',
        },
      },
      children: [
        {
          $formkit: 'primeCheckbox',
          name: 'generateDatevId',
          label: 'Debitor Buchungskonto generieren',
          id: 'generateDatevId',
          value: true,
        },
        {
          // if: '$value.generateCustomerNumber',
          if: '$value.generateDatevId === false',
          $formkit: 'primeInputText',
          key: 'datevIdInput',
          name: 'datevId',
          label: 'Debitor Buchungskonto',
          validation: 'required',
        },
      ],
    },
  ],
};

const customerNumberFieldset = {
  $cmp: 'Fieldset',
  props: {
    legend: customerType === 'customer' ? 'Kundennummer' : 'Partnernummer',
    collapsed: true,
    toggleable: true,
    value: '$value',
  },
  children: [
    {
      $el: 'div',
      attrs: {
        class: {
          // if: '$value.generateCustomerNumber',
          if: '$value.generateCustomerNumber === false',
          then: 'md:grid md:grid-cols-2 md:gap-3',
        },
      },
      children: [
        {
          $formkit: 'primeCheckbox',
          name: 'generateCustomerNumber',
          label: customerType === 'customer' ? 'Kundennummer generieren' : 'Partnernummer generieren',
          id: 'generateCustomerNumber',
          value: true,
        },
        {
          // if: '$value.generateCustomerNumber',
          if: '$value.generateCustomerNumber === false',
          $formkit: 'primeInputText',
          key: 'customerNumberInput',
          name: 'customerNumber',
          label: customerType === 'customer' ? 'Kundennummer' : 'Partnernummer',
          placeholder: '#123456',
          validation: 'required',
        },
      ],
    },
  ],
};

const customerSchema: Ref<FormKitSchemaDefinition> = reactive({
  $formkit: 'form',
  submitLabel: {
    if: '$value.id',
    then: customerType === 'customer' ? 'Kunde aktualisieren' : 'Partner aktualisieren',
    else: customerType === 'customer' ? 'Kunde erstellen' : 'Partner erstellen',
  },
  submitAttrs: {
    inputClass: 'p-button p-component',
  },
  children: [
    {
      $formkit: 'hidden',
      name: 'id',
    },
    ...customerBaseDataSchema,
    {
      $formkit: 'primeInputText',
      name: 'email',
      label: 'E-Mail',
      KEY: 'email',
      help: `Die E-Mail Adresse des ${customerType === 'customer' ? 'Kunden' : 'Partners'}, dient als Zugang zum Kundenportal, Rechnungsversand und für Benachrichtigungen.`,
      validation: 'required|email',
      placeholder: 'max@example.com',
    },
    {
      ...customerVatIdSchema,
      if: '$value.customerType === "business" || $value.customerType === "soleProprietor"',
    },
    ...customerGenericSchema,
    customerNumberFieldset,
    addressFieldset,
    customerContactDataFieldSetSchema,
    deferredInvoiceDataFieldset,
    accountingBasicFlag.value
      ? datevFieldset
      : {
          $formkit: 'hidden',
          name: 'datevId',
          value: null,
        },
    {
      $formkit: 'hidden',
      name: 'gender',
      value: null,
    },
    usePermissions().can(AppPermissions.CustomerGroupRead) && customerGroupFlag.value
      ? customerGroupFieldSchema
      : {
          $formkit: 'hidden',
          name: 'customerGroup',
          value: undefined,
          ignore: true,
        },
    usePermissions().can(AppPermissions.ContactPersonRead) && contactPersonFlag.value
      ? contactPersonFieldSchema
      : {
          $formkit: 'hidden',
          name: 'contactPerson',
          value: undefined,
          ignore: true,
        },
    taxExemptDropdownSchema,
    customFieldsFlag
      ? {
          $formkit: 'customFields',
          name: 'customFields',
          entityType: 'customer',
        }
      : {
          $formkit: 'hidden',
          name: 'customFields',
          value: {},
        },
  ],
});

const singleTranslation = customerType === 'customer' ? 'Kunde' : 'Partner';

const onSubmit = async (data: any, node: FormKitNode) => {
  await submitHandler(
    {
      route: customerType === 'customer' ? '/api/v1/customers' : '/api/v1/partners',
      method: 'POST',
      customErrorHandler() {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Der ${singleTranslation} konnte nicht erstellt werden. Überprüfe die Eingaben und versuche es erneut.`,
          life: 3500,
        });
      },
      successHandler(response) {
        toast.add({
          severity: 'success',
          summary: `${singleTranslation} erstellt`,
          detail: `Der ${singleTranslation} "${response.data.customerNumber}" wurde erfolgreich erstellt.`,
          life: 3500,
        });

        emit('onSuccess', response.data);

        // @ts-ignore
        dialogRef?.value.close(response.data);
      },
    },
    data,
    node
  );
};
</script>

<template>
  <div>
    <FormKitSchema
      v-model="data"
      :schema="customerSchema"
      :library="library"
      @submit="onSubmit"
      @node="(node) => nodeHandler(node, 'create-customer')"
    />
  </div>
</template>

<style scoped lang="scss"></style>
