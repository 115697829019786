export const transformPaymentMethodItemToDropdownValue = (data: any, t, labelSuffix = '') => {
  let label = t('entities.PaymentMethod.type.options.' + data.type + '.label')
  if (data.sepaDebit) {
    label += ' (' + (data.sepaDebit?.iban ?? data.sepaDebit?.mandateReference) + ')'
  }
  if (data.card) {
    label += ' (**** **** **** ' + data.card.last4 + ' - ' + data.card.expMonth + '/' + data.card.expYear + ')'
  }

  if (labelSuffix) {
    label += ' ' + labelSuffix
  }

  return {
    ...data,
    label,
    value: data.id
  }
}
