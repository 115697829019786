<script setup lang="ts">
import PriceExampleCalculator from "~/components/Price/PriceExampleCalculator.vue";
import {useToast} from "primevue/usetoast";
import Interval from "~/components/Interval.vue";
import PricePlanInplaceTitle from "~/components/Price/PricePlanInplaceTitle.vue";
import PricePlanTierItem from "~/components/Price/PricePlanTierItem.vue";
import {pricePlanFormFields as pricePlanFormFieldsT, priceTypeNonRecurringOptions, priceTypeRecurringOptions} from "~/formkit/pricePlan";
import PricePlanFormFields from "~/components/Price/PricePlanFormFields.vue";
import PricePlanService from "~/services/PricePlanService";
import {useConfirm} from "primevue/useconfirm";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  customerId: {
    type: String,
    required: false
  },
  productId: {
    type: String,
    required: false,
  },
  editable: {
    type: Boolean,
    required: false,
    default: false
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  singleRow: {
    type: Boolean,
    required: false,
    default: false
  },
  currencyCode: {
    type: String,
    required: false,
    default: null
  },
  forceCurrencyCode: {
    type: Boolean,
    required: false,
    default: false
  },
  createLabel: {
    type: String,
    required: false,
    default: 'Preis erstellen'
  },
  onSubmit: {
    type: Function,
    required: false,
    default: null
  },
  beforeSubmit: {
    type: Function,
    required: false,
    default: null
  },
  editableBillingInterval: {
    type: Boolean,
    required: false,
    default: false
  }
})

if (!props.productId && !props.data?.product) {
  throw new Error('One of the props is required: productId or product')
}

const toast = useToast()
const pricePlanService = new PricePlanService(useConfirm(), useModal(), useMessage());

const currencyCode = computed(() => {
  if (props.forceCurrencyCode) {
    return props.currencyCode
  }
  if (props.data?.currencyCode) {
    return props.data.currencyCode
  }

  return props.currencyCode
})

const data = ref({
  ...props.data,
  currencyCode: currencyCode.value,
})

const emit = defineEmits(['onPriceUpdated'])

const createPricePlan = async (data: any) => {
  const createdPricePlan = await pricePlanService.createPricePlan(data);
  emit('onPriceUpdated', createdPricePlan);
}

const updatePricePlan = async (data: any) => {
  if (props.beforeSubmit) {
    const result = await props.beforeSubmit(data)
    if (!result) {
      toast.add({
        severity: 'info',
        summary: 'Preis nicht aktualisiert',
        detail: `Es wurden keine Änderungen vorgenommen.`,
        life: 3500,
      })
      return
    }
  }

  const updatedPricePlan = await pricePlanService.updatePricePlan(data);
  emit('onPriceUpdated', updatedPricePlan);
}

const onSubmit = async (data: any) => {
  // delete all form_* fields
  Object.keys(data).forEach((key) => {
    if (key.startsWith('form_')) {
      delete data[key]
    }
  })

  if (props.onSubmit) {
    await props.onSubmit(data)
    return
  }

  if (!data.id) {
    await createPricePlan(data)
  } else {
    await updatePricePlan(data)
  }
}
</script>

<template>
  <div class="price-plan-form" :class="{
    'single-row': singleRow
  }">
    <FormKit type="form" :actions="false" #default="{ value }" :disabled="!editable" @submit="onSubmit" v-model="data">
      <slot />
      <PricePlanFormFields
        :billing-interval-editable="editableBillingInterval"
        :value="value" :product-id="productId" :show-title="showTitle" :hide-billing-interval-and-currency-code="!!$slots.default"
      />

      <FormKit type="submitButton" :createLabel="createLabel" updateLabel="Preis aktualisieren" :entityId="value.id" v-if="editable" />
    </FormKit>
  </div>
</template>

<style lang="scss">
.price-plan-form.single-row {
  .price-plan-form__price, .price-plan-form__billing {
    grid-template-columns: 1fr;
  }
}
</style>
