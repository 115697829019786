<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
import AddPriceButtonForm from "~/components/Price/AddPriceButtonForm.vue";

const dialogRef: Ref<DynamicDialogInstance> = inject('dialogRef');

if (!dialogRef.value) {
  throw new Error('dialogRef is required');
}

const intervalOptions = dialogRef.value.data?.intervalOptions;
if (!intervalOptions) {
  throw new Error('intervalOptions is required');
}

const onSubmit = (data: any) => {
  dialogRef.value.close(data);
};
</script>

<template>
  <AddPriceButtonForm @on-submit="onSubmit" :interval-options="intervalOptions"/>
</template>

<style scoped lang="scss">

</style>
