<script setup lang="ts">

import useMessage from "~/composables/useMessage";
import {Ref} from "@vue/reactivity";
import {DynamicDialogInstance} from "primevue/dynamicdialogoptions";

const message = useMessage();
const dialogRef: Ref<DynamicDialogInstance> = inject('dialogRef');

const onSubmit = async (data: any) => {
  await $fetch('/api/v1/customer-groups', {
    method: 'POST',
    body: JSON.stringify(data),
    onResponse({ response }) {
      if (response.status === 201) {
        message.show('customerGroupCreateSuccess', { name: response._data.name });

        if (dialogRef.value) {
          dialogRef.value.close(response._data)
        }
      } else {
        message.show('customerGroupCreateError');
      }
    }
  })
}
</script>

<template>
  <div class="w-[400px]">
    <FormKit type="form" :actions="false" @submit="onSubmit">
      <FormKit type="primeInputText" name="name" label="Name" required validation="required|length:3,255" placeholder="Kundengruppe A" />
      <FormKit type="primeInputText" name="description" label="Beschreibung" required validation="length:3,255" :nullable="true" placeholder="Optionale Beschreibung der Kundengruppe" />

      <FormKit type="primeSubmitButton" submit-label="Kundengruppe erstellen" button-icon="pi pi-plus" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
