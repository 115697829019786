import { copyToClipboard } from '~/utils/clipboard';

const tooltipDirective = (app: any, t: any) => {
  app.directive('copy', {
    mounted(el: any, binding: any) {
      init(el, binding, t, false);
    },
    updated(el: any, binding: any) {
      init(el, binding, t, false);
    },
    unmounted(el: any) {
      // check if $destroyCopy function exists on el
      if (el?.$destroyCopy) {
        el.$destroyCopy();
      }
    },
  });

  app.directive('copyid', {
    mounted(el: any, binding: any) {
      init(el, binding, t, true);
    },
    updated(el: any, binding: any) {
      init(el, binding, t, true);
    },
    unmounted(el: any) {
      el.$destroyCopy();
    },
  });
};

function init(el: any, binding: any, t: any, copyId = false) {
  const position = binding.arg || 'right';
  const copyText = binding.value || '';

  if (copyText.length === 0) {
    return;
  }
  el.setAttribute('copy-tooltip-position', position);
  el.setAttribute('copy-text', copyText);

  el.setAttribute('copy-tooltip', !copyId ? 'Kopieren' : 'ID kopieren');
  el.setAttribute('copy-tooltip-copied', false);

  el.$copyValue = copyText;
  const handler = (event) => {
    event.stopPropagation();

    copyToClipboard(el.$copyValue);
    el.setAttribute('copy-tooltip', !copyId ? 'Kopiert ✅' : 'ID kopiert ✅');
    el.setAttribute('copy-tooltip-copied', true);
    setTimeout(() => {
      if (copyId) {
        el.setAttribute('copy-tooltip', 'ID kopieren');
      } else {
        el.setAttribute('copy-tooltip', 'Kopieren');
      }
      el.setAttribute('copy-tooltip-copied', false);
    }, 2000);
    if (el.$copyCallback) {
      el.$copyCallback(el.$copyValue);
    }
  };

  const handlePositionOverflow = () => {
    const rect = el.getBoundingClientRect();
    if (rect.right + 200 > window.innerWidth) {
      el.setAttribute('copy-tooltip-position', 'left');
    }

    if (rect.bottom + 100 > window.innerHeight) {
      el.setAttribute('copy-tooltip-position', 'top');
    }

    if (rect.top - 100 < 0) {
      el.setAttribute('copy-tooltip-position', 'bottom');
    }
  }

  el.addEventListener('click', handler);
  el.addEventListener('mouseover', handlePositionOverflow)
  el.$destroyCopy = () => el.removeEventListener('click', handler);

  handlePositionOverflow();
}

export default defineNuxtPlugin((nuxtApp) => {
  const t = null;
  tooltipDirective(nuxtApp.vueApp, t);
});
