<script setup lang="ts">
import CreateProductFamilyForm from "~/components/ProductFamily/CreateProductFamilyForm.vue";
import AppDropdownCreateForm from "~/components/AppDropdownCreateForm.vue";
import {useToast} from "primevue/usetoast";
import FormKitApiDropdownModal from "~/components/FormKit/FormKitApiDropdownModal.vue";

const toast = useToast()

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  modelValue: {
    type: Boolean,
    required: true
  },
  onSubmit: {
    type: Function,
    required: true
  },
  refresh: {
    type: Function,
    required: false
  },
  setInputValue: {
    type: Function,
    required: false
  },
  // allow to override schema for input field
  fieldSchema: {
    type: Object,
    required: false,
    default: null
  },
  asModal: {
    type: Boolean,
    required: false,
    default: false
  },
  modalSubmitLabel: {
    type: String,
    required: false,
    default: undefined
  },
  modalSubmitIcon: {
    type: String,
    required: false,
    default: 'pi pi-plus'
  },
  modalWidth: {
    type: String,
    required: false,
    default: 'min-w-[400px]'
  }
})

const emit = defineEmits(['update:modelValue', 'onSuccess'])
const modal = useModal();

const processOnSubmit = async (data: any) => {
  await props.onSubmit(data, {
    refresh: props.refresh,
    setInputValue: props.setInputValue,
    toast
  })
}

const toggle = () => {
  if (props.asModal) {
    modal.open(FormKitApiDropdownModal, {
      props: {
        header: props.title,
      },
      data: {
        submitFunction: processOnSubmit,
        submitLabel: props.modalSubmitLabel ?? props.title,
        formSchema: props.fieldSchema,
        modalWidth: props.modalWidth,
        submitIcon: props.modalSubmitIcon,
      },
      onClose(options) {
        if (options?.data) {
          emit('onSuccess', options.data)
        }
      },
    })

    return;
  }

  emit('update:modelValue', !props.modelValue)
}

const onSuccess = (data: any) => {
  emit('onSuccess', data)
}
</script>
<template>
  <div class="f-dropdown-create-item">
    <button type="button" class="p-link" @click="toggle" v-show="!modelValue">
      {{ title }}
    </button>

    <div v-if="modelValue">
      <div class="font-semibold text-sm -mt-1 mb-1">
        {{ title }}
      </div>
      <AppDropdownCreateForm :on-submit="processOnSubmit" @on-success="onSuccess" :field-schema="fieldSchema" />
    </div>
  </div>
</template>
