<script setup lang="ts">
import type {PropType} from "vue";
import type {FormKitFrameworkContext} from "@formkit/core";
import {computed} from "vue";

type DateInputProps = {
  class?: string;
  max?: string;
  min?: string;
  step?: string;
  dateType?: 'date' | 'month';
}

const props = defineProps({
  context: Object as PropType<FormKitFrameworkContext & DateInputProps>
});

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;

  props.context?.node?.input(value);
}

const onBlur = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;

  props.context?.handlers.blur(value);
}

const styleClass = computed(() => {
  return (props.context?.state.validationVisible && !props.context?.state.valid) ? `${props.context?.class ?? ''} p-invalid` : props.context?.class ?? '';
});
</script>

<template>
  <div>
    <input
      :id="context?.id"
      v-model="context._value"
      :disabled="context?.attrs?.disabled"
      :readonly="context?.attrs?.readonly"
      :tabindex="context?.attrs?.tabindex"
      :placeholder="context?.attrs?.placeholder"
      :max="context?.max"
      :min="context?.min"
      :step="context?.step"
      class="p-inputtext p-component"
      :class="styleClass"
      :type="context?.dateType ?? 'date'"
      @input="onInput"
      @blur="onBlur"
    />
  </div>
</template>

<style scoped>

</style>
