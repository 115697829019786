<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {FormKitSchemaDefinition} from "@formkit/core";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Panel from "primevue/panel";
import {customerAddressSchema} from "~/formkit/customer";
import {useToast} from "primevue/usetoast";
import {PropType} from "vue";

const toast = useToast();
const emit = defineEmits(['onSuccess'])

const dialogRef = inject("dialogRef");

const props = defineProps({
  customerId: {
    type: String,
    required: false,
    default: undefined
  },
  type: {
    type: String as PropType<'TYPE_COMPANY' | 'TYPE_INVOICE'>,
    required: false,
    default: undefined
  }
})

const customerId = props.customerId ?? dialogRef.value?.data?.customerId ?? null
const type: 'TYPE_COMPANY' | 'TYPE_INVOICE' = props.type ?? dialogRef.value?.data?.type ?? 'TYPE_COMPANY'

if (type !== 'TYPE_COMPANY' && type !== 'TYPE_INVOICE') {
  throw new Error('type must be TYPE_COMPANY or TYPE_INVOICE')
}

if (!customerId) {
  throw new Error('[customerEmailAddressForm] customerId is required')
}

const data = ref({
  type,
  customerId
})

const library = markRaw({
  Accordion: Accordion,
  AccordionTab: AccordionTab,
  Card: Panel,
})

const formSchema: Ref<FormKitSchemaDefinition> = reactive({
  $formkit: 'form',
  submitLabel: {
    if: '$value.id',
    then: 'E-Mail Adresse aktualisieren',
    else: 'E-Mail Adresse hinzufügen',
  },
  submitAttrs: {
    inputClass: 'p-button p-component w-full -mb-3',
  },
  children: [
    {
      $formkit: 'hidden',
      name: 'id',
    },
    {
      $formkit: 'hidden',
      name: 'customerId',
    },
    {
      $formkit: 'hidden',
      name: 'type',
    },
    {
      $formkit: 'primeInputText',
      name: 'receiverName',
      label: 'Empfänger',
      validation: 'required|length:3,255',
    },
    {
      $formkit: 'primeInputText',
      name: 'email',
      label: 'E-Mail Adresse',
      validation: 'required|email',
    },
  ]
})

const onSubmit = async (data: any) => {
  await $fetch(`/api/v1/customers/${customerId}/email-addresses`, {
    method: 'POST',
    body: JSON.stringify(data),
    onResponse({ response }) {
      if (response.status === 201) {
        toast.add({
          severity: 'success',
          summary: 'E-Mail Adresse hinzugefügt',
          detail: `Die -Mail Adresse wurde erfolgreich hinzugefügt.`,
          life: 3500
        })

        emit('onSuccess', response._data)

        // @ts-ignore
        dialogRef?.value.close(response._data)
      } else {
        toast.add({
          severity: 'error',
          summary: 'Fehler',
          detail: `Die -Mail Adresse konnte nicht hinzugefügt werden.`,
          life: 3500
        })
      }
    }
  })
}
</script>

<template>
  <div>
    <FormKitSchema :schema="formSchema" v-model="data" :library="library" @submit="onSubmit" />
  </div>
</template>

<style scoped lang="scss">

</style>
