import iban from './iban';
import requiredNullable from './requiredNullable';
import currencyCode from '~/formkit/rules/currencyCode';
import vatId from '~/formkit/rules/vatId';
import emailArray from '~/formkit/rules/emailArray';

export const rules = {
  currencyCode,
  iban,
  requiredNullable,
  vatId,
  emailArray,
};
