<script setup lang="ts">
import {EditorContent, useEditor} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import {PropType} from 'vue'
import {FormKitFrameworkContext} from "@formkit/core";
import {extractVariablesFromHTML, parseHTMLAndExtractVariables, Variable} from "~/utils/tiptap/variable";
import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import TipTapVariableList from "~/components/TipTapVariableList.vue";



export type TipTapEditorProps = {
  activeButtons?: ('bold'|'italic'|'strike'|'underline'|'bulletList'|'orderedList'|'undo'|'redo'|'blockquote')[],
  minHeight?: string,
}

const props = defineProps({
  context: {
    type: Object as PropType<FormKitFrameworkContext & TipTapEditorProps>,
    required: true,
  },
});

const activeButtons = computed(() => props.context.activeButtons ?? ['bold']);
const minHeight = computed(() => props.context.minHeight ?? '200px');

const isEditable = computed(() => {
  return !(props.context?.attrs?.disabled ?? false) && !(props.context?.attrs?.readonly ?? false);
});

const editor = useEditor({
  extensions: [
    StarterKit,
    Underline,
    // Variable.configure({
    //   // customItems: [],
    //   suggestion: {
    //     render: () => {
    //       let component;
    //       let popup;
    //
    //       return {
    //         onStart: (props) => {
    //           component = new VueRenderer(TipTapVariableList, {
    //             props: props,
    //             editor: props.editor,
    //           });
    //
    //           if (!props.clientRect) {
    //             return;
    //           }
    //
    //           popup = tippy("body", {
    //             getReferenceClientRect: props.clientRect,
    //             content: component.element,
    //             showOnCreate: true,
    //             interactive: true,
    //             trigger: "manual",
    //             placement: "bottom-start",
    //           });
    //         },
    //
    //         onUpdate(props) {
    //           component.updateProps(props);
    //
    //           if (!props.clientRect) {
    //             return;
    //           }
    //
    //           popup[0].setProps({
    //             getReferenceClientRect: props.clientRect,
    //           });
    //         },
    //
    //         onKeyDown(props) {
    //           if (props.event.key === "Escape") {
    //             popup[0].hide();
    //
    //             return true;
    //           }
    //
    //           return component?.ref?.onKeyDown(props);
    //         },
    //
    //         onExit() {
    //           popup[0]?.destroy();
    //           component.destroy();
    //         },
    //       };
    //     },
    //   },
    // }),
  ],
  editable: isEditable.value,
  content: props.context?._value,
  onUpdate: ({ editor }) => {
    props.context?.node.input(
      extractVariablesFromHTML(
        editor.getHTML()
      )
    );
  },
});

watch(() => props.context?._value, (value) => {
  value = parseHTMLAndExtractVariables(value);

  if (!editor.value) return;
  if (value === editor.value.getHTML()) return;

  editor.value.commands.setContent(value);
});

watch(() => isEditable.value, (value) => {
  if (!editor.value) return;
  editor.value.setEditable(value);
});

const onFocus = (event: PointerEvent) => {
  if (!editor.value) return;
  // check if event is clicked inside 'tiptap' element
  // find editor of clicked element
  const tiptapElement = (event.target as HTMLElement).closest('.tiptap');
  if (tiptapElement) return;

  const menubarElement = (event.target as HTMLElement).closest('.menubar');
  if (menubarElement) return;

  editor.value.commands.focus('end');
};
</script>

<template>
  <div class="editor p-inputtext" @click="onFocus">
    <div class="menubar" v-if="editor">
      <span v-for="actionName in activeButtons" :key="actionName">
        <button
            type="button"
            v-if="actionName === 'bold'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="editor.chain().focus().toggleBold().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"/></svg>
        </button>
        <button
            type="button"
            v-if="actionName === 'italic'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="editor.chain().focus().toggleItalic().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'strike'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('strike') }"
            @click="editor.chain().focus().toggleStrike().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M161.3 144c3.2-17.2 14-30.1 33.7-38.6c21.1-9 51.8-12.3 88.6-6.5c11.9 1.9 48.8 9.1 60.1 12c17.1 4.5 34.6-5.6 39.2-22.7s-5.6-34.6-22.7-39.2c-14.3-3.8-53.6-11.4-66.6-13.4c-44.7-7-88.3-4.2-123.7 10.9c-36.5 15.6-64.4 44.8-71.8 87.3c-.1 .6-.2 1.1-.2 1.7c-2.8 23.9 .5 45.6 10.1 64.6c4.5 9 10.2 16.9 16.7 23.9H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H270.1c-.1 0-.3-.1-.4-.1l-1.1-.3c-36-10.8-65.2-19.6-85.2-33.1c-9.3-6.3-15-12.6-18.2-19.1c-3.1-6.1-5.2-14.6-3.8-27.4zM348.9 337.2c2.7 6.5 4.4 15.8 1.9 30.1c-3 17.6-13.8 30.8-33.9 39.4c-21.1 9-51.7 12.3-88.5 6.5c-18-2.9-49.1-13.5-74.4-22.1c-5.6-1.9-11-3.7-15.9-5.4c-16.8-5.6-34.9 3.5-40.5 20.3s3.5 34.9 20.3 40.5c3.6 1.2 7.9 2.7 12.7 4.3l0 0 0 0c24.9 8.5 63.6 21.7 87.6 25.6l0 0 .2 0c44.7 7 88.3 4.2 123.7-10.9c36.5-15.6 64.4-44.8 71.8-87.3c3.6-21 2.7-40.4-3.1-58.1H335.1c7 5.6 11.4 11.2 13.9 17.2z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'underline'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('underline') }"
            @click="editor.chain().focus().toggleUnderline().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M16 64c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H128V224c0 53 43 96 96 96s96-43 96-96V96H304c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V96H48C30.3 96 16 81.7 16 64zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'code'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('code') }"
            @click="editor.chain().focus().toggleCode().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'h1'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 96c0-17.7-14.3-32-32-32S0 78.3 0 96V256 416c0 17.7 14.3 32 32 32s32-14.3 32-32V288H256V416c0 17.7 14.3 32 32 32s32-14.3 32-32V256 96c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H64V96zm448 0c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-64 32c-15.8 7.9-22.2 27.1-14.3 42.9s27.1 22.2 42.9 14.3l17.7-8.8V384H416c-17.7 0-32 14.3-32 32s14.3 32 32 32h64 64c17.7 0 32-14.3 32-32s-14.3-32-32-32H512V96z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'h2'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 96c0-17.7-14.3-32-32-32S0 78.3 0 96V256 416c0 17.7 14.3 32 32 32s32-14.3 32-32V288H256V416c0 17.7 14.3 32 32 32s32-14.3 32-32V256 96c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H64V96zm385.9 47.4c11.6-9.9 26.4-15.4 41.7-15.4H496c35.3 0 64 28.7 64 64v5.8c0 17.9-7.5 35.1-20.8 47.2L378.4 392.4c-9.7 8.9-13 22.9-8.2 35.2S386.8 448 400 448H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H482.3l100.2-91.9c26.4-24.2 41.5-58.5 41.5-94.4V192c0-70.7-57.3-128-128-128h-4.5c-30.6 0-60.1 10.9-83.3 30.8l-29 24.9c-13.4 11.5-15 31.7-3.5 45.1s31.7 15 45.1 3.5l29-24.9z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'h3'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M64 96c0-17.7-14.3-32-32-32S0 78.3 0 96V256 416c0 17.7 14.3 32 32 32s32-14.3 32-32V288H256V416c0 17.7 14.3 32 32 32s32-14.3 32-32V256 96c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H64V96zM400 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H514.7l-89.4 89.4c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h72c30.9 0 56 25.1 56 56s-25.1 56-56 56H455.4c-11.7 0-21.7-8.5-23.7-20.1l-.2-1.2c-2.9-17.4-19.4-29.2-36.8-26.3s-29.2 19.4-26.3 36.8l.2 1.2c7.1 42.4 43.8 73.5 86.8 73.5H520c66.3 0 120-53.7 120-120c0-64.6-51-117.2-114.9-119.9l89.5-89.5c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H400z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'bulletList'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"/></svg>
        </button>
        <button
            type="button"
            v-if="actionName === 'orderedList'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'blockquote'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('blockquote') }"
            @click="editor.chain().focus().toggleBlockquote().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M88 0C39.4 0 0 39.4 0 88v56 24 24c0 26.5 21.5 48 48 48H96c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H64V88c0-13.3 10.7-24 24-24h8c17.7 0 32-14.3 32-32s-14.3-32-32-32H88zM264 0c-48.6 0-88 39.4-88 88v56 24 24c0 26.5 21.5 48 48 48h48c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H240V88c0-13.3 10.7-24 24-24h8c17.7 0 32-14.3 32-32s-14.3-32-32-32h-8zM576 424V368 344 320c0-26.5-21.5-48-48-48H480c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h32v8c0 13.3-10.7 24-24 24h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8c48.6 0 88-39.4 88-88zM312 512c48.6 0 88-39.4 88-88V368 344 320c0-26.5-21.5-48-48-48H304c-26.5 0-48 21.5-48 48v48c0 26.5 21.5 48 48 48h32v8c0 13.3-10.7 24-24 24h-8c-17.7 0-32 14.3-32 32s14.3 32 32 32h8z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'codeBlock'"
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('codeBlock') }"
            @click="editor.chain().focus().toggleCodeBlock().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'horizontalRule'"
            class="menubar__button"
            @click="editor.chain().focus().setHorizontalRule().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M0 256c0-17.7 14.3-32 32-32H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'undo'"
            class="menubar__button"
            @click="editor.chain().focus().undo().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/></svg>
        </button>

        <button
            type="button"
            v-if="actionName === 'redo'"
            class="menubar__button"
            @click="editor.chain().focus().redo().run()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>
        </button>
      </span>
    </div>

    <editor-content :editor="editor" class="editor__content" :style="{
      'minHeight': minHeight,
    }" />
  </div>
</template>

<style lang="scss" scoped>
.editor {
  .menubar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 0.25rem;

    .menubar__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0.5rem;
      border-radius: 3px;
      border: 1px solid transparent;
      background-color: transparent;
      color: #999;
      fill: #999;
      height: 2rem;
      width: 2rem;
      transition: all 0.2s ease-in-out;
      gap: 0.125rem;

      svg {
        height: 1.125rem;
      }

      &:hover {
        background-color: #eee;
      }

      &.is-active {
        background-color: #eee;
        color: #333;
      }
    }
  }

  .editor__content {
    padding: 0.5rem;
  }
}
</style>
<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  & {
    outline: none;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
}

.editor__content {
  padding: 1rem 0 0;

  h3 {
    margin: 1rem 0 0.5rem;
  }

  pre {
    border-radius: 5px;
    color: #333;
  }

  code {
    display: block;
    white-space: pre-wrap;
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
    background-color:#e9ecef;
    color: #495057;
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: circle;

    li {
      margin-bottom: 0.5rem;
    }
  }

  ol {
    padding-left: 1.5rem;
    list-style-type: decimal;

    li {
      margin-bottom: 0.5rem;
    }
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
}
</style>
