import { defineNuxtRouteMiddleware } from '#imports';
import { useUser } from '~/store/user';

type MiddlewareMeta =
  | boolean
  | {
      unauthenticatedOnly: true;
      navigateAuthenticatedTo?: string;
    };

declare module '#app/../pages/runtime/composables' {
  interface PageMeta {
    auth?: MiddlewareMeta;
  }
}

const authClient = useAuthClient();

export default defineNuxtRouteMiddleware(async (to) => {
  // due to access on cookies, this middleware can only be used on the client
  if (process.server) return;

  const metaAuth = to.meta.auth as MiddlewareMeta | undefined;
  if (metaAuth === false) {
    return;
  }

  const status = authClient.isAuthed() ? 'authenticated' : authClient.isRefreshable() ? 'refreshable' : 'unauthenticated';
  const isGuestMode = typeof metaAuth === 'object' && metaAuth.unauthenticatedOnly;
  // Guest mode happy path 1: Unauthenticated user is allowed to view page
  if (isGuestMode && status === 'unauthenticated') {
    return;
  }

  // Guest mode happy path 2: Authenticated user is allowed to view page
  if (isGuestMode && status === 'authenticated' && metaAuth.navigateAuthenticatedTo) {
    return metaAuth.navigateAuthenticatedTo;
  }

  // Authenticated user is allowed to view page
  if (status === 'authenticated') {
    const userStore = useUser();
    const onUserLoaded = (user: any) => {
      if (user === null) {
        // user is not authenticated anymore

        // redirect to login page
        return '/login?redirect=' + to.path;
      }

      if (user?.isConfirmed === false) {
        if (to.path === '/onboarding/verify-email') {
          return;
        }
        return '/onboarding/verify-email';
      }

      if (user?.onboardingRequired) {
        return '/onboarding';
      }
    };

    if (!userStore.getUser || !userStore.getPermissions) {
      // await, otherwise "Sie haben keine Berechtigung" is shown, because no permissions exists initially. need to fix this, so the message is reactive.
      return await userStore.loadUser().then(onUserLoaded);
    } else {
      // make async to avoid blocking the middleware
      userStore.loadUser().then((user) => {
        const result = onUserLoaded(user);
        if (result) {
          navigateTo(result);
        }
      });
    }

    return;
  }

  if (status === 'refreshable') {
    if (import.meta.browser) {
      await authClient.refreshTokens();
    }
    return;
  }

  // Unauthenticated user is not allowed to view page
  return '/login?redirect=' + to.path;
});
