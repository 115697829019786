import { createMessage, FormKitNode } from '@formkit/core';

/**
 * A contrived validation rule that checks if the value is a valid iban.
 */
const vatId = async function (node: FormKitNode) {
  node.store.remove('rule_vatid_invalid');
  node.store.remove('rule_vatid_api_error');
  node.context.iconRight = '';

  const inputValue = node.value?.toUpperCase();
  if (!inputValue && node.props.validationRules?.required) {
    return false;
  }

  if (!inputValue) {
    return true;
  }

  const validatingIbanMessage = createMessage({
    key: 'rule_vatid_validating',
    value: 'Die angegebene Steuernummer wird überprüft...',
    visible: true,
    blocking: true,
    type: 'loading',
  });

  node.store.set(validatingIbanMessage);

  node.context.iconRight = 'pi pi-spin pi-spinner';

  const countryCodeField = node.props?.attrs?.countryCodeField || false;
  const countryCodeNode = node.parent?.find(countryCodeField);

  const currentHelpText = node.props.help || '';

  // when node.value starts with a two-letter country code, we remove it and use it, otherwise countryCodeNode.value
  const countryCodeValue = inputValue.match(/^[A-Z]{2}/)?.[0] || countryCodeNode?.value || '';
  if (!countryCodeValue) {
    const apiErrorMessage = createMessage({
      key: 'rule_vatid_invalid',
      value: 'Es wurde kein Ländercode gefunden. Die Steuernummer muss mit einem Ländercode beginnen.',
      visible: true,
      blocking: true,
      type: 'validation',
    });

    node.store.set(apiErrorMessage);

    return;
  }

  const vatIdValue = inputValue.replace(countryCodeValue, '');

  const onlineCheckRequired = true;
  const debug = true;

  const { data, status } = await useFetch('/api/check-vatid', {
    method: 'POST',
    body: JSON.stringify({
      vatId: vatIdValue,
      country: countryCodeValue,
    }),
  });

  console.log(data);

  node.store.remove('rule_vatid_validating');

  node.props.help = currentHelpText;

  node.context.iconRight = '';

  if (result.isValid === false) {
    node.context.iconRight = 'pi pi-exclamation-triangle p-invalid-icon';
    const apiErrorMessage = createMessage({
      key: 'rule_vatid_api_error',
      value: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      visible: true,
      blocking: true,
      type: 'validation',
    });

    node.store.set(apiErrorMessage);

    return;
  }

  if (result.isValid === false) {
    node.context.iconRight = 'pi pi-times p-invalid-icon';
    toggleFieldVisibility(false, true);

    const invalidMessage = createMessage({
      key: 'rule_vatid_invalid',
      value: 'Die angegebene Steuernummer ist ungültig.',
      visible: true,
      blocking: true,
      type: 'validation',
    });

    node.store.set(invalidMessage);
  } else {
    node.context.iconRight = 'pi pi-check p-valid-icon';
  }

  return true;
};

vatId.blocking = true;
vatId.skipEmpty = false;
vatId.debounce = 20; // milliseconds
vatId.force = true;

export default vatId;
