<script setup lang="ts">
import PaymentMethodService from "~/services/PaymentMethodService";
import {PropType} from "vue";

const props = defineProps({
  type: {
    type: String as PropType<'card' | 'paypal' | 'sepa_debit' | 'bank_transfer' | 'wallet'>,
    required: true,
  },
})

const paymentMethodService = new PaymentMethodService()


const label = computed(() => {
  return paymentMethodService.getLabel(props.type)
})

const piIcon = computed(() => {
  return paymentMethodService.getIcon(props.type)
})
</script>

<template>
  <span>
    <span class="p-mr-2">
      <i :class="'pi pi-' + piIcon" />
    </span>
    {{ $t(`entities.PaymentMethod.type.options.${type}.label`) }}
  </span>
</template>

<style scoped lang="scss">

</style>
