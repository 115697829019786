import {Ref} from "@vue/reactivity";
import flagsmith from "flagsmith";

export type IRegisteredFlag = {
  name: string;
  value: Ref<boolean>;
}

export type IRegisteredFlags = IRegisteredFlag[]

export const setIdentity = (userId: string) => {
  useState('flagsmithIdentity', () => userId).value = userId;
  if (process.client && flagsmith) {
    flagsmith.identify(userId);
  }
}

const getFlagValue = (name: string) => {
  // @ts-ignore
  return useState('flagValues', () => {}).value[name]?.enabled ?? false;
}

const getIdentity = () => {
  return useState('flagsmithIdentity', () => null).value;
}

export const useFlag = (name: string) => {
  const flagsmithRegisteredFlags = useState<IRegisteredFlags>('registeredFlags');
  if (!flagsmithRegisteredFlags.value) {
    flagsmithRegisteredFlags.value = [];
  }

  const flag = ref<boolean>(Boolean(getFlagValue(name)));
  flagsmithRegisteredFlags.value.push({
    name,
    value: flag,
  });

  return flag;
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const publicConfig = useRuntimeConfig().public;

  const flagsmithRegisteredFlags = useState<IRegisteredFlags>('registeredFlags', () => []);

  const updateRegisteredFlags = () => {
    const allFlags = useState('flagValues', () => {}).value;
    Object.entries(allFlags).forEach(([flagName, flagValue]) => {
      flagsmithRegisteredFlags.value.filter((flag) => flag.name === flagName).forEach((flag) => {
        if (flag.value !== Boolean(flagValue.enabled)) {
          console.log(`Updating flag ${flagName} to ${flagValue.enabled}`);
          flag.value = Boolean(flagValue.enabled);
        }
      });
    });
  }

  const flagValuesState = useState('flagValues', () => {});

  if (process.server) {
    let url = publicConfig.flagsmithApi + 'identities/?identifier=' + getIdentity();
    if (!getIdentity()) {
      url = publicConfig.flagsmithApi + 'flags/';
    }

    const result = await $fetch(url, {
      headers: {
        'X-Environment-Key': publicConfig.flagsmithEnvironmentId as string,
        'Content-Type': 'application/json',
      }
    })

    const flags = result?.flags || result;

    const factoredFlagState = {}
    flags.forEach((flag: any) => {
      factoredFlagState[flag.feature.name] = {
        enabled: flag.enabled,
      }
    })

    flagValuesState.value = factoredFlagState;

    updateRegisteredFlags();
  }

  if (process.client) {
    if (flagsmith) {
      await flagsmith.init({
        environmentID: publicConfig.flagsmithEnvironmentId as string,
        api: publicConfig.flagsmithApi as string,
        cacheFlags: true,
        enableAnalytics: true,
        onChange: (oldFlags, params) => {
          //Occurs whenever flags are changed
          const { isFromServer } = params; //determines if the update came from the server or local cached storage
          flagValuesState.value = flagsmith.getAllFlags();
          updateRegisteredFlags();
        },
      });

      flagsmith.startListening(15000);
    }
  }
});
