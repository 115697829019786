<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {DynamicDialogInstance} from "primevue/dynamicdialogoptions";
import {inject} from "@vue/runtime-core";

const dialogRef: Ref<DynamicDialogInstance> = inject('dialogRef') as Ref<DynamicDialogInstance>;

const { submitFunction, submitLabel, formSchema, modalWidth, submitIcon } = dialogRef.value.data;
if (!submitFunction || !submitLabel || !formSchema) {
  throw new Error('submitFunction, submitLabel and formSchema are required');
}

const message = useMessage();

const onSubmit = async (formData: any) => {
  await submitFunction(formData).then((result) => {
    dialogRef.value.close(result);
  }).catch((e) => {
    console.log(e);
    message.show('errorOccured');
  });
}
</script>

<template>
  <div :class="modalWidth ?? 'min-w-[280px]'">
    <FormKit type="form" :actions="false" @submit="onSubmit">
      <FormKitSchema :schema="formSchema" />

      <FormKit type="primeSubmitButton" :submit-label="submitLabel" :button-icon="submitIcon" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>
