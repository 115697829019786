import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import Editor from 'primevue/editor';
import FocusTrap from 'primevue/focustrap';
import CodeHighlight from '../directives/code';
import primeLocaleDe from "primelocale/de.json";
import primeLocaleEn from "primelocale/en.json";

export declare interface AppState {
  theme?: string;
  darkTheme?: boolean;
}

export default defineNuxtPlugin(({ vueApp: app }) => {
  app.use(PrimeVue, {
    ripple: true,
    inputStyle: 'outlined',
    // unstyled: true,
    // pt: Tailwind
    locale: {
      ...primeLocaleDe['de'],
      fileSizeTypes: primeLocaleEn['en']['fileSizeTypes'],
    },
  });

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(DialogService);

  app.component('DynamicDialog', DynamicDialog);
  app.component('Editor', Editor);

  app.directive('tooltip', Tooltip);
  app.directive('ripple', Ripple);
  app.directive('code', CodeHighlight);
  app.directive('badge', BadgeDirective);
  app.directive('styleclass', StyleClass);
  app.directive('focustrap', FocusTrap);

  const appState = createGlobalState(() =>
    useLocalStorage<AppState>('app-state', {
      theme: 'fynn-light',
      darkTheme: false,
    })
  )();

  return {
    provide: {
      appState: reactiveComputed(() => appState.value),
    },
  };
});

declare module '@nuxt/schema' {
  interface NuxtApp {
    $appState: AppState;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $appState: AppState;
    outsideClickListener?: ((event: Event) => void) | null;
  }
}
