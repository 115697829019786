<script setup lang="ts">
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FormKitNode } from '@formkit/core';
import { priceTypeNonRecurringOptions, priceTypeRecurringOptions } from '~/formkit/pricePlan';
import { useFlag } from '~/plugins/flagsmith';

const props = defineProps({
  value: {
    type: Object as PropType<any>,
    required: true,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
  productId: {
    type: String,
    required: true,
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true,
  },
  billingIntervalEditable: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideBillingIntervalAndCurrencyCode: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const message = useMessage();
const billingPayInAreas = useFlag('billing.pay_in_areas');
const priceUpdateAssistantFlag = useFlag('price.update-assistant');
const { t } = useI18n();

const payInAdvanceOptions = computed(() => {
  const options = [{ label: t('pricePlan.payInAdvance.options.true'), value: true }];

  if (billingPayInAreas.value) {
    options.push({ label: t('pricePlan.payInAdvance.options.false'), value: false });
  }

  return options;
});

const pricingTypeOptions = [
  { label: 'Wiederkehrend', value: 'recurring', help: 'Erhebe eine wiederkehrende Gebühr' },
  { label: 'Einmal-Zahlung', value: 'non_recurring', help: 'Erhebe eine Einmal-Gebühr' },
];

const onPricingTypeNode = (node: FormKitNode) => {
  // when pricingType is set to non-recurring, the billingInterval should be set to null and prorata to false

  const handlePricingTypeValue = (value: any) => {
    const billingIntervalNode = node.parent?.find('billingInterval');
    const proRata = node.parent?.find('proRata');
    const type = node.parent?.find('type');
    const showPricePerInterval = node.parent?.find('showPricePerInterval');
    const excludeFromGlobalPriceUpdates = node.parent?.find('excludeFromGlobalPriceUpdates');
    const freeUnits = node.parent?.find('freeUnits');

    if (value === 'non_recurring') {
      console.log('avalue', value);
      billingIntervalNode?.input(null);
      proRata?.input(false);
      showPricePerInterval?.input(null);
      excludeFromGlobalPriceUpdates?.input(false);
      freeUnits?.input(null);

      if (type && !['per_unit', 'flat_fee'].includes(type._value as string)) {
        type?.input('per_unit');
        message.warning('Der Preistyp wurde auf "Betrag pro Einheit" gesetzt, da dieser Preistyp nur für einmalige Zahlungen verfügbar ist.');
      }
    } else {
      console.log('avalue', value);
      billingIntervalNode?.input(
        billingIntervalNode?._value === null ? '1M' : billingIntervalNode?._value
      );
      proRata?.input(true);
    }
  }

  node?.on('input', ({ payload: value }) => {
    handlePricingTypeValue(value);
  });
};

const isBillingPeriodEligbleForShowPricePerInterval = computed(() => {
  if (props.value.billingInterval === '1M') {
    return false;
  }
  // when billingInterval ends with M or Y
  return props.value.billingInterval?.endsWith('M') || props.value.billingInterval?.endsWith('Y');
});
</script>

<template>
  <div class="flex gap-3 flex-col">
    <FormKit type="hidden" name="id" />

    <!-- Pricing type selector, required to adapt the form -->
    <FormKit
      type="cardSelectButton"
      :options="pricingTypeOptions"
      name="pricingType"
      :value="value.billingInterval === null ? 'non_recurring' : 'recurring'"
      @node="onPricingTypeNode"
    />

    <FormKit
      v-if="value.pricingType === 'recurring'"
      type="primeDropdown"
      name="type"
      label="Preistyp"
      :options="priceTypeRecurringOptions($t)"
      value="per_unit"
      help="Der Preistyp bestimmt, wie der Preis berechnet wird."
      validation="required"
    />

    <FormKit
      v-else-if="value.pricingType === 'non_recurring'"
      type="primeDropdown"
      name="type"
      label="Preismodell"
      :options="priceTypeNonRecurringOptions($t)"
      value="per_unit"
      help="Wähle das passende Preismodell für dein Produkt aus."
      validation="required"
    />

    <!-- required because the api requires the productId as field, todo: maybe move this to the upper form to not need to pass productId as prop -->
    <FormKit type="hidden" name="product" :value="productId" />

    <FormKit type="currencyDropdown" name="currencyCode" label="Währung" validation="required" :preselect-option="true" :return-code="true" />

    <div class="gap-3 price-plan-form__price">
      <div class="grid grid-cols-1 gap-3">
        <div v-if="(value.type === 'per_unit' || value.type === 'flat_fee') && value.currencyCode">
          <FormKit key="unit_fee" type="group" name="price" label="Preis" validation="required">
            <FormKit
              type="primeInputNumber"
              name="amount"
              :label="value.type === 'flat_fee' ? 'Pauschal wiederkehrender Betrag' : 'Betrag pro Einheit'"
              mode="currency"
              :currency="value.currencyCode"
              :min-fraction-digits="2"
              :max-fraction-digits="10"
              :value="0"
              validation="required"
            />
          </FormKit>
        </div>

        <div v-if="(value.type === 'volume' || value.type === 'tiered' || value.type === 'stair_step') && value.currencyCode">
          <FormKit key="tiered" type="group" name="price" label="Preis" validation="required">
            <FormKit
              type="pricePlanTier"
              :currency-code="value.currencyCode"
              name="items"
              validation="min:1"
              :value="[
                {
                  amount: 0,
                  from: 0,
                  flatAmount: 0,
                },
              ]"
            />
          </FormKit>
        </div>

        <div v-if="value.type === 'percentage' && value.currencyCode" class="space-y-3">
          <FormKit key="percentage" type="group" name="price" label="Preis" validation="required">
            <FormKit
              type="primeInputNumber"
              name="amount"
              label="Betrag pro Einheit"
              mode="currency"
              :currency="value.currencyCode"
              :min-fraction-digits="2"
              :max-fraction-digits="10"
              :value="0"
              validation="required"
              help="Der Betrag, der pro Einheit berechnet wird. Dieser Betrag wird mit der Anzahl der Einheiten (- prozentualer Anteil) multipliziert."
            />

            <FormKit
              type="primeInputNumber"
              name="percentage"
              label="Prozentualer Anteil"
              suffix=" %"
              :min="0"
              :value="0"
              :max="100"
              :min-fraction-digits="2"
              :max-fraction-digits="10"
              validation="required"
              help="Der prozentuale Anteil der Einheiten, die berechnet werden. Bei Nutzung von 100 Einheiten und einem prozentualen Anteil von 30% werden 30 Einheiten berechnet."
            />

            <FormKit
              type="primeInputNumber"
              name="freeUnitsPerEvent"
              label="Freie Einheiten pro Event"
              :min-fraction-digits="2"
              :max-fraction-digits="10"
              suffix=" Einheiten"
              :value="0"
              help="Die Anzahl der inklusiven Einheiten pro gemeldeten verbrauchten Einheiten."
            />

            <FormKit
              type="primeInputNumber"
              name="freeUnitsPerTotalAggregated"
              label="Freie Einheiten pro Abrechnungszeitraum"
              :min-fraction-digits="2"
              :max-fraction-digits="10"
              :value="0"
              help="Die Anzahl der inklusiven Einheiten pro Abrechnungszeitraum."
            />
          </FormKit>
        </div>
      </div>
    </div>

    <!-- next todo: Preis-Input neu erstellen mit Number + Währungsauswahl, nur das die ggfs. blockiert werden kann -->

    <FormKit
      v-if="value.pricingType === 'recurring'"
      type="billingIntervalDropdown"
      name="billingInterval"
      validation="required"
      label="Abrechnung erfolgt"
      validation-label="Abrechnungsinterval"
      value="1M"
      :preserve="true"
      :disabled="!billingIntervalEditable"
      :help="billingIntervalEditable ? '' : 'Bitte beachte, dass das Abrechnungsintervall nicht geändert werden kann, da das Abo bereits aktiv ist.'"
    />
    <FormKit v-else type="hidden" name="billingInterval" :value="null" :preserve="true" />

    <!-- internalName -->
    <FormKit
      v-if="showTitle"
      type="primeInputText"
      name="internalName"
      label="Beschreibung"
      validation="length:3,255"
      nullable
      placeholder="Gebe dem Preis eine Beschreibung um ihn besser zuordnen zu können."
    />
    <FormKit v-else type="hidden" name="internalName" :value="null" />

    <div class="md:grid md:grid-cols-1 gap-4 price-plan-form__billing">
      <FormKit
        type="primeDropdown"
        name="payInAdvance"
        label="Fälligkeit"
        :model-value="true"
        :options="payInAdvanceOptions"
        help="Lege fest, zu welchem Zeitpunkt der Preis fällig wird."
        validation="required"
      />

      <FormKit
        v-if="value.pricingType === 'recurring' && isBillingPeriodEligbleForShowPricePerInterval"
        type="primeInputSwitch"
        name="showPricePerInterval"
        true-value="1M"
        :false-value="null"
        label="Preis pro Monat anzeigen"
        help="Zeige den Preis pro Monat im Warenkorb, Rechnung und Kundenbereich an."
        :value="null"
        :preserve="true"
      />
      <FormKit v-else type="hidden" name="showPricePerInterval" :value="null" :preserve="true" />

      <FormKit
        type="primeInputSwitch"
        name="applyTrial"
        label="Kostenlose Testphase erlauben"
        help="Biete eine kostenlose Testphase an, bevor der Preis berechnet wird."
        :value="true"
      />

      <FormKit
        v-if="value.pricingType === 'recurring'"
        type="primeInputSwitch"
        name="proRata"
        label="Beträge anteilig berechnen"
        help="Berechne den Preis bei Mengen- und Zeitraumänderungen anteilig."
        :value="true"
        :preserve="true"
      />
      <FormKit v-else type="hidden" name="proRata" :value="false" :preserve="true" />

      <div v-if="priceUpdateAssistantFlag && value.pricingType === 'recurring'">
        <FormKit
          type="primeInputSwitch"
          name="excludeFromGlobalPriceUpdates"
          label="Von globalen Preisaktualisierungen ausschließen"
          help="Schließe diesen Preis von allen zukünftigen Preisaktualisierungen aus."
          :value="false"
          :preserve="true"
        />
      </div>
      <FormKit v-else type="hidden" name="excludeFromGlobalPriceUpdates" :value="false" :preserve="true" />
    </div>

    <div v-if="value.pricingType === 'recurring'">
      <FormKit
        type="primeInputNumber"
        name="freeUnits"
        label="Freie Einheiten"
        suffix=" Einheiten"
        :nullable="true"
        placeholder="0"
        help="Inklusiv-Einheiten die vom Preis abgedeckt sind."
        :preserve="true"
      />
    </div>
    <FormKit type="hidden" name="freeUnits" :value="null" :preserve="true" />
  </div>
</template>

<style scoped lang="scss"></style>
