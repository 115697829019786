<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import {AppPermissions} from "~/types/Permissions";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import CreateBillingGroupFrom from "~/components/Billing/CreateBillingGroupFrom.vue";
import {useApiDropdown} from "~/store/api-dropdown";
import useModal from "~/composables/useModal";
import CostCentreModal from "~/components/CostCentre/CostCentreModal.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const modal = useModal();

const headerComponentProps = {
  title: 'Kostenstelle hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    modal.open(CostCentreModal, {
      props: {
        header: 'Kostenstelle hinzufügen',
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.attrs.onCreate?.(data.data)
          await refresh()
          useApiDropdown().addItem(data.data)
          setInputValue(data.data.id)
        }
      }
    })
  }
}
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="`/api/v1/accounting/cost-centres`"
      :header-component-permissions="[AppPermissions.CostCentreWrite]"
      :header-component="AppDropdownCreateButton"
      :header-component-props="headerComponentProps"
      :api-transform-function="(data: any, { lang }) => {
        return {
          ...data,
          label: data.name + ' (' + data.code + ')',
          value: data.id
        }
      }"
      :default-placeholder="'Kostenstelle auswählen'"
      :preload-options="true"
      :show-filter="true"
    />
  </div>
</template>
