<script lang="ts" setup>
import { FormKitFrameworkContext } from '@formkit/core';
import { PropType } from 'vue';
import Chips from 'primevue/chips';

const props = defineProps({
  context: {
    type: Object as PropType<FormKitFrameworkContext & {
      max?: Number;
      allowDuplicate?: boolean;
    }>,
    required: true,
  },
});

const onUpdate = (value: string[]) => {
  if (props.context.nullable || props.context.attrs.nullable) {
    if (value.length === 0) {
      props.context.node.input(null);
      return;
    }
  }

  props.context.node.input(value);
};

const initValue = () => {
  if (!Array.isArray(props.context._value) && typeof props.context._value !== 'object') {
    return [];
  }

  return Object.values(props.context._value);
};
</script>

<template>
  <div>
    <Chips :model-value="initValue()" class="w-full" @update:model-value="onUpdate" :placeholder="context?.placeholder || context.attrs.placeholder" :max="context?.max" :allow-duplicate="context?.allowDuplicate" />
  </div>
</template>

<style lang="scss">
.p-chips {
  .p-chips-multiple-container {
    @apply w-full;
  }
}
</style>
