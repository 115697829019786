<script setup lang="ts">
import ErrorBoundary from "~/components/ErrorBoundary.vue";
import AppFallbackLoader from "~/components/AppFallbackLoader.vue";

defineProps({
  style: {
    type: String,
    default: ''
  },
})

const dialogRef = inject('dialogRef');
</script>

<template>
  <ErrorBoundary>
    <Suspense>
      <template #fallback>
        <AppFallbackLoader :style="style" />
      </template>

      <slot v-if="!dialogRef?.data?.component" />
      <component v-else :is="dialogRef.data.component" v-bind="dialogRef.data?.props ?? {}" />
    </Suspense>
  </ErrorBoundary>
</template>

<style scoped lang="scss">

</style>
