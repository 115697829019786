<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import CreateCustomerForm from "~/components/Customer/CreateCustomerForm.vue";
import {useDialog} from "primevue/usedialog";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import CustomerAddressForm from "~/components/Customer/CustomerAddressForm.vue";
import {AppPermissions} from "~/types/Permissions";
import CustomerEmailAddressForm from "~/components/Customer/CustomerEmailAddressForm.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const dialog = useDialog()

const placeholder = computed(() => props.context?.attrs?.placeholder ?? props.context?.placeholder ?? 'Standard E-Mailadresse verwenden')
const addressType = computed(() => props.context?.attrs?.addressType ?? props.context?.addressType ?? 'TYPE_INVOICE')
const filterByType = computed(() => props.context?.attrs?.filterByType ?? props.context?.filterByType ?? false)

if (addressType.value !== 'TYPE_INVOICE' && addressType.value !== 'TYPE_COMPANY') {
  throw new Error('addressType must be TYPE_INVOICE or TYPE_COMPANY')
}

const customerId = computed(() => props.context?.customerId ?? null)
if (!customerId.value) {
  throw new Error('customerId is required')
}

const apiEndpoint = computed(() => {
  let filter = ''
  if (filterByType.value) {
    filter = '?type=' + addressType.value
  }

  return '/api/v1/customers/' + customerId.value + '/email-addresses' + filter
})

const headerComponentProps = {
  title: addressType.value === 'TYPE_INVOICE' ? 'Rechnungs E-Mailadresse hinzufügen' : 'Kontakt E-Mailadresse hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(CustomerEmailAddressForm, {
      props: {
        header: addressType.value === 'TYPE_INVOICE' ? 'Rechnungs E-Mailadresse hinzufügen' : 'Kontakt E-Mailadresse hinzufügen',
        modal: true,
      },
      data: {
        customerId: customerId.value,
        type: addressType.value,
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await props.context?.attrs.onCreate?.(data.data)
          await refresh()

          setInputValue(data.data.id)
        }
      }
    })
  }
};
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="apiEndpoint"
      :api-transform-function="(data: any, { lang }) => {
        const name = `${data.email} - ${data.receiverName}`
        return {
          ...data,
          label: name,
          value: data.id
        }
      }"
      :default-placeholder="placeholder"
      :preload-options="true"
      :header-component-props="headerComponentProps"
      :header-component="AppDropdownCreateButton"
      :header-component-permissions="[AppPermissions.CustomerWrite]"
    />
  </div>
</template>
