<script setup lang="ts">
import {onMounted} from "vue";
import Sortable from "sortablejs";
import autoAnimate from "@formkit/auto-animate";

export type OptionMovedEvent = {
  oldIndex?: number,
  newIndex?: number,
  originalEvent: Sortable.SortableEvent
}

const props = defineProps({
  sortable: {
    type: Boolean,
    default: false
  }
})

const sortable = ref<HTMLElement | null>(null)
const emit = defineEmits({
  "optionMoved": (event: OptionMovedEvent) => true
});

onMounted(() => {
  if (!sortable.value) {
    return
  }

  Sortable.create(sortable.value, {
    draggable: '.app-option-item',
    handle: '.app-option-item--drag-button',
    onEnd: (event) => {
      emit("optionMoved", {
        oldIndex: event.oldIndex,
        newIndex: event.newIndex,
        originalEvent: event
      })
    }
  });

  autoAnimate(sortable.value);
})
</script>

<template>
  <div class="app-option-list" ref="sortable">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.app-option-list {
  @apply flex flex-col gap-3;
}
</style>
