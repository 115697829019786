<script setup lang="ts">
import {FormKitSchemaDefinition} from "@formkit/core";
import Button from "primevue/button";
import {$fetch} from "ofetch";
import {useToast} from "primevue/usetoast";

const dialogRef = inject('dialogRef')
const toast = useToast()

const customerId = computed(() => {
  return dialogRef.value.data.customerId
})

const library = markRaw({
  Button: Button
})

const formSchema: FormKitSchemaDefinition = reactive({
  $formkit: 'form',
  actions: false,
  children: [
    {
      $formkit: 'hidden',
      name: 'customer',
      value: customerId,
    },
    {
      $formkit: 'primeInputText',
      name: 'name',
      label: 'Name',
      validation: 'required',
      help: 'Interner Name zur Wiedererkennung',
      placeholder: 'z.B. "Abrechnung zu Beginn des Monats"'
    },
    {
      $formkit: 'primeDropdown',
      name: 'type',
      label: 'Zeitpunkt',
      validation: 'required',
      options: [
        { value: 'start_of_month', label: 'Monatsanfang' },
        { value: 'end_of_month', label: 'Monatsende' },
        { value: 'start_of_year', label: 'Jahresanfang' },
        { value: 'end_of_year', label: 'Jahresende' },
        { value: 'custom', label: 'Benutzerdefiniert' },
      ],
      value: 'start_of_month',
      help: 'Zeitpunkt der Abrechnung',
    },
    {
      if: '$value.type === "custom"',
      $formkit: 'primeInputNumber',
      name: 'customDay',
      label: 'Tag',
      validation: 'required|integer|min:1|max:31',
      min: 1,
      max: 31,
      suffix: '. des Monats',
      value: 1,
    },
    {
      $formkit: 'hidden',
      name: 'customMonth',
      value: null,
    },
    {
      $cmp: 'Button',
      props: {
        label: 'Abrechnungsgruppe hinzufügen',
        type: 'submit',
        icon: 'pi pi-plus',
        class: 'mt-4 w-full !-mb-2',
      }
    }
  ]
})

const onSubmit = async (data: any) => {
  try {
    const result = await $fetch('/api/v1/billing-groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        customer: data.customer,
        name: data.name,
        type: data.type,
        customDay: data.customDay,
        customMonth: data.customMonth,
      },
    })

    dialogRef?.value?.close(result)

    toast.add({
      severity: 'success',
      summary: 'Abrechnungsgruppe erstellt',
      detail: `Die Abrechnungsgruppe "${result.name}" wurde erfolgreich erstellt.`,
      life: 3500,
    })
  } catch {
    toast.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Die Abrechnungsgruppe konnte nicht erstellt werden.',
      life: 3500,
    })
  }
}
</script>

<template>
  <div class="create-billing-group-form">
    <FormKitSchema :schema="formSchema" :library="library" @submit="onSubmit" />
  </div>
</template>

<style scoped lang="scss">
.create-billing-group-form {
  max-width: 400px;
}

@media (max-width: 400px) {
  .create-billing-group-form {
    max-width: 100%;
  }
}
</style>
