import { defineStore } from 'pinia';
import { useApiDropdown } from '~/store/api-dropdown';

export const usePricePlan = defineStore('pricePlan', {
  state: () => ({
    items: [],
  }),
  actions: {
    async addPricePlan(item) {
      if (!item) {
        return;
      }

      if (!item?.id) return;

      const index = this.items.findIndex((c) => c.id === item.id);
      if (index === -1) {
        this.items.push(item);
      } else {
        this.items[index] = item;
      }
    },
    async addPricePlans(items) {
      if (!items) {
        return;
      }

      items.forEach((item) => {
        if (!item?.id) return;

        this.addPricePlan(item);
      });
    },
    async fetchPricePlan(id) {
      const item = await $fetch('/api/v1/price-plans/' + id);

      if (!item) {
        return;
      }

      useApiDropdown().addItem(item);
      this.addPricePlan(item);

      return item;
    },
  },
  getters: {
    getById: (state) => (id) => {
      return state.items.find((c) => c.id === id) || null;
    },
  },
});
