import {useConfirm} from "primevue/useconfirm";

export type ConfirmationOptions = {
  rejectLabel?: string;
  acceptLabel?: string;
  acceptIcon?: string;
  rejectIcon?: string;
  acceptClass?: string;
  rejectClass?: string;
  header?: string;
  message: string;
  icon?: string;
}

export default function useConfirmation() {
  const confirm = useConfirm();

  /**
   * Show a confirmation dialog and wait until the user has confirmed or rejected the action. If user has canceled the action, the promise will be rejected.
   * @param options
   */
  const waitingConfirm = async (options: ConfirmationOptions): Promise<boolean | null> => {
    const confirmResult = ref<null | boolean | undefined>(undefined);

    let icon = options?.icon || 'pi pi-exclamation-triangle';
    if (options?.icon === null) {
      icon = undefined;
    }

    confirm.require({
      ...options,
      acceptLabel: options?.acceptLabel || 'Bestätigen',
      rejectLabel: options?.rejectLabel || 'Abbrechen',
      icon,
      header: options?.header || 'Bestätigung erforderlich',
      acceptClass: options?.acceptClass,
      rejectClass: options?.rejectClass,
      acceptIcon: options?.acceptIcon || 'pi pi-check',
      rejectIcon: options?.rejectIcon || 'pi pi-times',
      blockScroll: true,
      accept: () => {
        confirmResult.value = true;
      },
      reject: () => {
        confirmResult.value = false;
      },
      onHide: () => {
        confirmResult.value = null;
      }
    })

    // wait until the user has confirmed or rejected the action
    await new Promise((resolve) => {
      watch(confirmResult, (newValue) => {
        if (newValue !== undefined) {
          resolve(undefined);
        }
      })
    })

    if (confirmResult.value === null) {
      // reject the promise if the user has canceled the action
      throw new Error('User has canceled the action');
    }

    if (confirmResult.value === undefined) {
      throw new Error('This should not happen');
    }

    return confirmResult.value;
  }

  return {
    waitingConfirm,
  }
}
