<script setup lang="ts">
const props = defineProps({
  isOptionValid: {
    type: Boolean,
    required: true,
  },
  fieldName: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['incomplete']);

const rootNode = ref(null)
const stateNode = ref(null)
const isFormIncomplete = ref(false)
const onNode = (a) => {
  stateNode.value = a
  rootNode.value = a.at('$root')
}

onMounted(() => {
  stateNode.value.hook.message((payload, next) => {
    if (payload.key === 'submitted' && payload.value === true) {
      isFormIncomplete.value = !props.isOptionValid
      emit('incomplete', isFormIncomplete.value)
    }

    return next(payload)
  })
})
</script>

<template>
  <div class="hidden" :key="isOptionValid">
    <FormKit type="checkbox" :model-value="isOptionValid" :name="fieldName" validation="accepted" @node="onNode" />
  </div>
</template>

<style scoped lang="scss">

</style>
