import { defineNuxtRouteMiddleware } from '#imports';
import {useFlag} from "~/plugins/flagsmith";

type MiddlewareMeta =
  | boolean
  | {
      unauthenticatedOnly: true;
      navigateAuthenticatedTo?: string;
    };

declare module '#app/../pages/runtime/composables' {
  interface PageMeta {
    featureFlags?: string[];
  }
}

export default defineNuxtRouteMiddleware(async (to) => {
  const requiredFeatureFlags = to.meta.featureFlags as string[] | undefined;
  if (!requiredFeatureFlags) {
    return;
  }

  for (const flag of requiredFeatureFlags) {
    const isEnabled = useFlag(flag);
    if (!isEnabled) {
      return '/?featureNotEnabled=true';
    }
  }
});
