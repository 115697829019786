<script setup lang="ts">
import {PropType} from "vue";
import {FormKitContext} from "@formkit/core";
import ApiDropdown from "~/formkit/inputs/ApiDropdown.vue";
import {AppPermissions} from "~/types/Permissions";
import AppDropdownCreateButton from "~/components/AppDropdownCreateButton.vue";
import CreateProductForm from "~/components/Product/CreateProductForm.vue";
import {useDialog} from "primevue/usedialog";
import {useApiDropdown} from "~/store/api-dropdown";
import {useProduct} from "~/store/product";
import CreateBillingGroupFrom from "~/components/Billing/CreateBillingGroupFrom.vue";

const props = defineProps({
  context: Object as PropType<FormKitContext>
})

const dialog = useDialog()
const type = props.context?.productType || undefined
const customerId = computed(() => {
  return props.context?.customerId || undefined
})

const apiEndpoint = computed(() => {
  return '/api/v1/customers/' + customerId.value + '/billing-groups'
})

const headerComponentProps = {
  title: 'Abrechnungsgruppe hinzufügen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(CreateBillingGroupFrom, {
      props: {
        header: 'Abrechnungsgruppe hinzufügen',
        modal: true,
      },
      data: {
        customerId: customerId.value,
      },
      onClose: async (data: any) => {
        console.log(data);
        if (data.data?.id) {
          await props.context.onCreate?.(data.data)
          await refresh()
          useApiDropdown().addItem(data.data)
          setInputValue(data.data.id)
        }
      }
    })
  }
}
</script>

<template>
  <div v-if="customerId">
    <ApiDropdown
      type="single"
      :context="context"
      :api-endpoint="apiEndpoint"
      :api-transform-function="(data: any, { lang }) => {
        let label = data.name
        if (data.customDay && !data.customMonth) {
          label = label + ' (' + data.customDay + '. des Monats)'
        } else if (data.customDay && data.customMonth) {
          label = label + ' (am ' + data.customDay + '.' + data.customMonth + ')'
        }

        return {
          ...data,
          label: label,
          value: data.id,
        }
      }"
      :default-placeholder="'Abrechnungsgruppe auswählen'"
      :header-component-props="headerComponentProps"
      :header-component="AppDropdownCreateButton"
      :header-component-permissions="[AppPermissions.BillingGroupWrite]"
    />
  </div>
  <div v-else>
    <p class="text-gray-500">Bitte wähle zunächst einen Kunden aus.</p>
  </div>
</template>
